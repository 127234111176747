// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var block = "NumberBlock-module--block--VLkr2";
export var hasText = "NumberBlock-module--hasText--vq7Q4";
export var wrapper = "NumberBlock-module--wrapper--k9Ywg";
export var isArrowLeft = "NumberBlock-module--isArrowLeft--LHZrJ";
export var number = "NumberBlock-module--number--U8LYy";
export var isNumberInView = "NumberBlock-module--isNumberInView--gm2sI";
export var text = "NumberBlock-module--text--PMdsw";
export var isTextInView = "NumberBlock-module--isTextInView--nwbp1";