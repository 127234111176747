// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Footer-module--container--6vDdF";
export var stars = "Footer-module--stars--+ak+H";
export var marquee = "Footer-module--marquee--MZIkS";
export var marqueeWrapper = "Footer-module--marqueeWrapper--hUO89";
export var logo = "Footer-module--logo--6uGtt";
export var ellipse = "Footer-module--ellipse--NtzXr";
export var topCaption = "Footer-module--topCaption--zUd+d";
export var arrows = "Footer-module--arrows--bjei7";
export var links = "Footer-module--links--B71sT";
export var bottomCaption = "Footer-module--bottomCaption--RbK4r";
export var bottomCaptionText = "Footer-module--bottomCaptionText--CXC8u";