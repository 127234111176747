import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames/bind'

import { SVGProps, PathProps } from './LogoTypes'
import * as s from './Logo.module.scss'
const cn = classNames.bind(s)

const dArr = [
  'M107.79 43.86C96.4 32.45 81.3 26 65.1 25.57C64.59 25.54 62.08 25.56 61.43 25.58C27.47 26.57 1.01001 51.58 1.01001 85.46V176.18C1.01001 177.55 2.12002 178.66 3.49002 178.66H23.52C24.89 178.66 26 177.55 26 176.18V140.27C26 140.27 26 138.04 26 138.28C36.71 146.23 49.68 150.54 63.37 150.54C80.22 150.54 95.99 144.03 107.78 132.21C119.52 120.44 125.99 104.75 125.99 88.04C126 71.32 119.53 55.63 107.79 43.86ZM63.37 125.53C43.26 125.53 26.91 108.71 26.91 88.03C26.91 67.35 43.27 50.53 63.37 50.53C83.47 50.53 99.84 67.35 99.84 88.03C99.84 108.71 83.48 125.53 63.37 125.53Z',
  'M161.02 1.07001H140.99C139.62 1.07001 138.51 2.18 138.51 3.55V148.05C138.51 149.42 139.62 150.53 140.99 150.53H161.02C162.39 150.53 163.5 149.42 163.5 148.05V3.55C163.5 2.18 162.39 1.07001 161.02 1.07001Z',
  'M294.75 95.87C294.75 128.65 270.72 150.53 241.04 150.53C211.35 150.53 188.5 125.53 188.5 95.87V31.14C188.5 29.77 189.61 28.66 190.98 28.66H211.01C212.38 28.66 213.49 29.77 213.49 31.14V97.22C213.49 113.03 225.38 125.53 241.03 125.53C256.67 125.53 269.74 113.03 269.74 97.22V31.14C269.74 29.77 270.46 28.66 271.84 28.66H292.26C293.63 28.66 294.74 29.77 294.74 31.14L294.75 95.87Z',
  'M364.52 28.39H343.16C342.3 28.39 341.61 27.7 341.61 26.84V3.00999C341.61 1.63999 340.5 0.529999 339.13 0.529999H319.68C318.31 0.529999 317.2 1.63999 317.2 3.00999V105.35C317.2 121.88 322.41 134.32 332.69 142.12C342.61 149.64 354 150.46 358.44 150.46C360.95 150.46 363.14 150.36 364.68 150.27C365.99 150.19 367 149.1 367 147.79V128.66C367 127.24 365.82 126.11 364.41 126.18C363.62 126.22 362.74 126.25 361.92 126.25C348.22 126.25 342.12 118.88 342.12 102.58V53.66C342.12 52.8 342.81 52.11 343.67 52.11H364.53C365.9 52.11 367.01 51 367.01 49.63V30.88C367 29.51 365.89 28.39 364.52 28.39Z',
  'M479.92 43.86C468.13 32.04 452.36 25.53 435.51 25.53C418.66 25.53 402.89 32.04 391.1 43.86C379.36 55.63 372.89 71.32 372.89 88.03C372.89 104.74 379.36 120.43 391.1 132.2C402.89 144.02 418.66 150.53 435.51 150.53C452.36 150.53 468.13 144.02 479.92 132.2C491.66 120.43 498.13 104.74 498.13 88.03C498.13 71.32 491.66 55.63 479.92 43.86ZM435.5 125.53C415.39 125.53 399.04 108.71 399.04 88.03C399.04 67.35 415.4 50.53 435.5 50.53C455.6 50.53 471.97 67.35 471.97 88.03C471.97 108.71 455.61 125.53 435.5 125.53Z',
]

const Path = ({ d, isInView }: PathProps) => {
  const ref = useRef<SVGPathElement>(null)
  const [isUndrawn, setIsUndrawn] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    const l = ref.current.getTotalLength().toFixed()
    ref.current.style.strokeDasharray = l
    ref.current.style.strokeDashoffset = l
    setIsUndrawn(true)
  }, [ref])

  useEffect(() => {
    if (!isInView || !isUndrawn || !ref.current) return
    ref.current.style.strokeDashoffset = `0`
  }, [ref, isInView, isUndrawn])

  return <path ref={ref} d={d} stroke='white' className={cn({ isInView })} />
}

const SVG = ({ ...props }: SVGProps) => {
  return (
    <svg viewBox='0 0 499 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {dArr.map((i, index) => (
        <Path key={i} d={dArr[index]} {...props} />
      ))}
    </svg>
  )
}

export default SVG
