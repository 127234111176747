// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var xr = "XR-module--xr--XnetX";
export var isInView = "XR-module--isInView--fgGho";
export var isActive = "XR-module--isActive--rzqIW";
export var r = "XR-module--r--EF0xd";
export var x = "XR-module--x--SNDVP";
export var inner = "XR-module--inner--rP5nD";
export var aGradient = "XR-module--aGradient--t5CTu";
export var aWhite = "XR-module--aWhite--ECMez";
export var vGradient = "XR-module--vGradient--CFjgm";
export var vWhite = "XR-module--vWhite--ZmNVD";
export var rGradient = "XR-module--rGradient--y2+Xk";