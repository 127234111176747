import React, { RefObject, memo } from 'react'
import { ViewportScrollScene } from '@14islands/r3f-scroll-rig'

import Sphere from 'components/xr/Sphere/'
import Outline from 'components/xr/Sphere/Outline'
import ForcedPerspective from 'components/xr/ForcedPerspective'
import type { SceneChildrenType } from 'types/ScrollRig'

type GroupProps = SceneChildrenType & {
  isSceneActive: boolean
  isInView: boolean
  isInteractedWith: boolean
}

const Group = ({ isSceneActive, isInView, isInteractedWith, ...props }: GroupProps) => {
  return (
    <>
      <group>
        <Sphere isSceneActive={isSceneActive} size={props.scale.width} />
        <Outline
          isSceneActive={isSceneActive}
          size={props.scale.width}
          isInView={isInView}
          isInteractedWith={isInteractedWith}
        />
      </group>
    </>
  )
}

type SceneProps = {
  el: RefObject<HTMLElement>
  is3D: boolean
  isInView: boolean
  isInteractedWith: boolean
}

const SphereScene = ({ el, is3D, isInView, isInteractedWith }: SceneProps) => {
  return (
    <ViewportScrollScene el={el} margin={100} priority={1001}>
      {(props: SceneChildrenType) => (
        <>
          <ForcedPerspective is3D={false} parallaxAmount={0} {...props}>
            <Group isSceneActive={is3D} isInView={isInView} isInteractedWith={isInteractedWith} {...props} />
          </ForcedPerspective>
        </>
      )}
    </ViewportScrollScene>
  )
}

export default memo(SphereScene)
