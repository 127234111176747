//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MathUtils, Group, Mesh, FrontSide } from 'three'
import { useSpring } from '@react-spring/three'
import { useFrame } from '@react-three/fiber'
import { easeExpOut, easeExpInOut } from 'd3-ease'
import { GLTF } from 'three-stdlib'

import model from 'assets/diamond/model.glb'
useGLTF.preload(model, false)

const sizeFactor = 0.06 //based on model size

type GLTFResult = GLTF & {
  nodes: {
    diamond: Mesh
  }
  materials: unknown
}

type DiamondProps = {
  isSceneActive: boolean
  size: number
  multiplier: number
}

const Diamond = ({ isSceneActive, size, multiplier, ...props }: DiamondProps) => {
  const { nodes, materials } = useGLTF(model, false) as GLTFResult
  const group = useRef<Group>()
  const mesh = useRef<Mesh>()
  const scale = size * sizeFactor

  useSpring({
    opacity: isSceneActive ? 1 : 0,
    delay: isSceneActive ? 100 : 1500,
    config: {
      duration: 1000,
      easing: isSceneActive ? easeExpOut : easeExpInOut,
    },
    onChange: spring => {
      mesh.current.material.opacity = spring.value.opacity
    },
  })

  const { rotationX } = useSpring({
    rotationX: isSceneActive ? 1 : 0,
    positionZ: isSceneActive ? 100 * multiplier : 0,
    rotationZ: isSceneActive ? Math.PI * 0.1 : 0,
    delay: isSceneActive ? 100 : 1500,
    config: {
      duration: isSceneActive ? 2000 : 1000,
      easing: easeExpOut,
    },
    onChange: spring => {
      if (!group || !group.current) return
      group.current.position.z = spring.value.positionZ
      group.current.rotation.z = spring.value.rotationZ
    },
  })

  useFrame(({ clock }) => {
    if (!group || !group.current) return
    if (isSceneActive) {
      group.current.rotation.x = rotationX.get() * Math.sin(clock.elapsedTime) * 0.25
    } else {
      if (group.current.rotation.x !== 0) {
        group.current.rotation.x = MathUtils.lerp(group.current.rotation.x, 0, 0.1)
      }
    }
  })

  return (
    <>
      <group ref={group} {...props} dispose={null} scale={scale}>
        <mesh
          ref={mesh}
          castShadow
          receiveShadow
          geometry={nodes.diamond_rounded_2.geometry}
          material={materials.initialShadingGroup}
          rotation={[Math.PI / 2, 0, 0]}
          color='#fff'
          material-opacity={0}
          material-side={FrontSide}
          material-transparent
          renderOrder={2}
        />
      </group>
    </>
  )
}

export default Diamond
