// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Content-module--container--8prvI";
export var isSmall = "Content-module--isSmall--O8wmF";
export var isLarge = "Content-module--isLarge--gfS8V";
export var wrapper = "Content-module--wrapper--3wuOO";
export var link = "Content-module--link--eAJWc";
export var text = "Content-module--text--7HsDd";
export var title = "Content-module--title--o7RUx";
export var isInView = "Content-module--isInView--XHLnM";
export var body = "Content-module--body--Cxh-c";
export var arrowBlockWrapper = "Content-module--arrowBlockWrapper--pgJjb";