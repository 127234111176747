import React, { useState } from 'react'
import classNames from 'classnames/bind'

import Line from 'components/ui/Line'
import ViewportEnter from 'components/core/ViewportEnter'
import Arrow from 'assets/svg/arrow.react.svg'

import { ArrowBlockType } from './ArrowBlockTypes'
import * as s from './ArrowBlock.module.scss'

const cn = classNames.bind(s)

const ArrowBlock = ({ isLeft = false, isHorizontal = false, hideOnDesktop = false }: ArrowBlockType) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <div className={cn('container', { isHorizontal, isLeft, hideOnDesktop })}>
      <Line position='top' transformOrigin={isLeft ? 'left top' : 'right top'} duration={isHorizontal ? 1 : 0.5} />
      {!isHorizontal && <Line position={isLeft ? 'right' : 'left'} duration={0.8} delay={0.5} hideOnMobile />}

      <ViewportEnter onEnter={() => setIsInView(true)}>
        <span>
          <Arrow className={cn('arrow', { isInView })} />
        </span>
      </ViewportEnter>
    </div>
  )
}

export default ArrowBlock
