// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Arrow-module--container--HioE8";
export var showOnMobile = "Arrow-module--showOnMobile--OmzxN";
export var wrapper = "Arrow-module--wrapper--yhO0t";
export var isInView = "Arrow-module--isInView--FGlbw";