import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { HijackedScrollbar, useCanvas } from '@14islands/r3f-scroll-rig'

import SEO from 'components/core/SEO'
import ViewportEnter from 'components/core/ViewportEnter'
import Hero from 'components/ui/Hero'
import GridLayout from 'components/ui/GridLayout'
import Footer from 'components/ui/Footer'
import PlutoLogoScene from 'components/xr/PlutoLogoScene'
import PlutoLogoStickyHoverTarget from 'components/ui/PlutoLogoStickyHoverTarget'
import Tagline from 'components/ui/Tagline'
import checkHoverSupport from 'lib/checkHoverSupport'

const Home = () => {
  const [scrolledToTop, setScrolledToTop] = useState(true)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [hoveringLogo, setHoveringLogo] = useState(false)
  const heroLogoAreaRef = useRef<HTMLDivElement>(null)
  const footerLogoAreaRef = useRef<HTMLDivElement>(null)
  const mouseRelative = useRef({ x: 0, y: 0 }).current
  const [hasHoverSupport, setHasHoverSupport] = useState(false)
  const logoSticky = !scrolledToTop && !scrolledToBottom

  const updateMesh = useCanvas(
    <PlutoLogoScene
      is3D={false}
      isClosed={false}
      isMinimized={false}
      el={heroLogoAreaRef}
      mouseRelative={mouseRelative}
      footerEl={footerLogoAreaRef}
    />,
  )

  useLayoutEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (hasHoverSupport) {
        updateMesh({ is3D: hoveringLogo && !logoSticky, isClosed: logoSticky, isMinimized: logoSticky })
      } else {
        updateMesh({ is3D: !logoSticky, isClosed: logoSticky, isMinimized: logoSticky })
      }
    }, 0)
  }, [updateMesh, logoSticky, hoveringLogo, hasHoverSupport])

  const onMouseMoveOverLogo = (relativePosition: { x: number; y: number }) => {
    mouseRelative.x = relativePosition.x
    mouseRelative.y = relativePosition.y
  }

  return (
    <>
      <SEO title='We help humanity transcend physical location.' />
      <HijackedScrollbar subpixelScrolling>
        {(bind: unknown) => (
          <main {...bind}>
            <Tagline tagline='We help humanity transcend physical location.' />
            <ViewportEnter
              onEnter={() => setScrolledToTop(true)}
              onExit={entry => {
                // If exit above viewport
                if (entry.boundingClientRect.top < 0) {
                  setScrolledToTop(false)
                }
              }}
              once={false}
              threshold={0.1}
            >
              <Hero
                ref={heroLogoAreaRef}
                onLogoHover={hover => hasHoverSupport && setHoveringLogo(hover)}
                onMouseMoveOverLogo={onMouseMoveOverLogo}
              />
            </ViewportEnter>
            <GridLayout />
            <ViewportEnter
              onEnter={() => setScrolledToBottom(true)}
              onExit={entry => {
                // If exit below viewport
                if (entry.boundingClientRect.top > 0) {
                  setScrolledToBottom(false)
                }
              }}
              once={false}
              threshold={0.5}
            >
              <Footer
                ref={footerLogoAreaRef}
                onLogoHover={hover => hasHoverSupport && setHoveringLogo(hover)}
                onMouseMoveOverLogo={onMouseMoveOverLogo}
              />
            </ViewportEnter>

            {logoSticky && (
              <PlutoLogoStickyHoverTarget
                onLogoHover={hover => hasHoverSupport && setHoveringLogo(hover)}
                onMouseMoveOverLogo={onMouseMoveOverLogo}
              />
            )}
          </main>
        )}
      </HijackedScrollbar>
    </>
  )
}

export default Home
