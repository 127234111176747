import React, { forwardRef, Ref } from 'react'
import classNames from 'classnames/bind'

import Caption from 'components/ui/Caption'
import DiamondGrid from 'components/ui/DiamondGrid'
import EllipseSvg from 'assets/svg/ellipse.react.svg'
import GradientBackground from 'components/ui/GradientBackground'
import Line from 'components/ui/Line'
import LogoSvg from 'assets/svg/pluto-wordmark-white.react.svg'
import Marquee from 'components/ui/Marquee'
import Stars from 'components/ui/Stars'

import Arrows from './Arrows'
import Link from './Link'
import * as s from './Footer.module.scss'

const cn = classNames.bind(s)

const Footer = (
  props: {
    onLogoHover: (hover: boolean) => void
    onMouseMoveOverLogo: (pos: { x: number; y: number }) => void
  },
  logoAreaRef: Ref<HTMLDivElement>,
) => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className={cn('container')}>
      <Stars className={cn('stars')} />
      <GradientBackground />

      <div className={cn('marquee')}>
        <Line position='top' duration={1} delay={0.4} />

        <Marquee repeatsPerInstance={6} speed={60} space='-100px'>
          <span className={cn('marqueeWrapper')}>
            <LogoSvg className={cn('logo')} />
            <EllipseSvg className={cn('ellipse')} />
          </span>
        </Marquee>

        <Line position='bottom' duration={1} delay={0.6} />
      </div>

      <Caption className={cn('topCaption')} lineAnimationDuration={1} lineAnimationDelay={0.8}>
        Extend your reality
      </Caption>

      <div className={cn('arrows')}>
        <DiamondGrid />
        <Arrows ref={logoAreaRef} onLogoHover={props.onLogoHover} onMouseMoveOverLogo={props.onMouseMoveOverLogo} />
      </div>

      <div className={cn('links')}>
        <Line position='top' duration={1} delay={0.4} />

        <Link
          caption='Contact us'
          title='Want to get in touch? You can reach out to us anytime.'
          button={{
            text: 'Reach out',
            url: 'mailto:support@plutovr.com',
          }}
          hasLine
          captionLineAnimationDelay={0.6}
        />

        <Link
          caption='We’re hiring'
          title='We’re booting up the next reality of computing. Be a part of it.'
          button={{
            text: 'Join Us',
            url: 'https://plutovr.applytojob.com/apply',
          }}
          captionLineAnimationDelay={0.8}
        />

        <Line position='bottom' duration={1.2} delay={1} />
      </div>

      <Caption hasLine={false}>
        <span className={cn('bottomCaption')}>
          <span className={cn('bottomCaptionText')}>Pluto.app</span>
          <span className={cn('bottomCaptionText')}>Copyright {currentYear} All Rights Reserved </span>
          <span className={cn('bottomCaptionText')}>Spatial website</span>
        </span>
      </Caption>
    </footer>
  )
}

export default forwardRef(Footer)
