import React, { RefObject, memo } from 'react'
import { ViewportScrollScene /*, ViewportScrollScene*/ } from '@14islands/r3f-scroll-rig'

import type { SceneChildrenType } from 'types/ScrollRig'
import SemiSphere from 'components/xr/SemiSphere/'
import Outline from 'components/xr/SemiSphere/Outline'
import ForcedPerspective from 'components/xr/ForcedPerspective'

type GroupProps = SceneChildrenType & {
  isSceneActive: boolean
  outlineType: 'left' | 'right'
  modelColor: 'white' | 'blue' | 'purple'
  isFlipped: boolean
  isIdle: boolean
  isInteractedWith: boolean
  delayIndex: number
}

const Group = ({
  isSceneActive,
  outlineType,
  modelColor,
  isFlipped,
  isIdle,
  isInteractedWith,
  delayIndex,
  ...props
}: GroupProps) => {
  return (
    <>
      <group>
        {!isIdle && (
          <SemiSphere isSceneActive={isSceneActive} size={props.scale.width} color={modelColor} isFlipped={isFlipped} /> // idle (disabled) on mobile
        )}
        <Outline
          isSceneActive={isSceneActive}
          width={props.scale.width}
          height={props.scale.height}
          type={outlineType}
          isIdle={isIdle}
          isInteractedWith={isInteractedWith}
          delayIndex={delayIndex}
        />
      </group>
    </>
  )
}

type SceneProps = {
  el: RefObject<HTMLElement>
  is3D: boolean
  isIdle?: boolean
  outlineType: 'left' | 'right'
  modelColor: 'white' | 'blue' | 'purple'
  isFlipped?: boolean
  isInteractedWith?: boolean
  delayIndex?: number
}

const SemiSphereScene = ({
  el,
  is3D,
  outlineType,
  modelColor,
  isFlipped = false,
  isIdle = false,
  isInteractedWith = false,
  delayIndex = 0,
}: SceneProps) => {
  return (
    <ViewportScrollScene el={el} margin={100} priority={1001}>
      {(props: SceneChildrenType) => (
        <>
          <ForcedPerspective is3D={false} parallaxAmount={0} {...props}>
            <Group
              isSceneActive={is3D}
              outlineType={outlineType}
              modelColor={modelColor}
              isFlipped={isFlipped}
              isIdle={isIdle}
              isInteractedWith={isInteractedWith}
              delayIndex={delayIndex}
              {...props}
            />
          </ForcedPerspective>
        </>
      )}
    </ViewportScrollScene>
  )
}

export default memo(SemiSphereScene)
