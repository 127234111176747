import React, {
  useRef,
  RefObject,
  ReactChildren,
  MutableRefObject,
  memo,
  useLayoutEffect,
  useEffect,
  Suspense,
} from 'react'
import { invalidate } from '@react-three/fiber'
import { a, useSpring } from '@react-spring/three'
import { Scene, Camera, CameraHelper, SpotLight, Group } from 'three'
import { ViewportScrollScene, useScrollRig } from '@14islands/r3f-scroll-rig'
import { easeExpOut } from 'd3-ease'

import useViewportHelper from 'lib/useViewportHelper'
import ForcedPerspective from 'components/xr/ForcedPerspective'
import CustomPerspective from 'components/xr/ForcedPerspective/CustomPerspective'
import type { SceneChildrenType } from 'types/ScrollRig'

export type GridModelWrapperProps = {
  children?: ReactChildren
  is3D?: boolean
  size?: number
  activeSize?: number
  rotation?: [number, number, number]
  activeRotation?: [number, number, number]
  position?: [number, number, number]
  activePosition?: [number, number, number]
  debugCamera?: boolean
}
export type GridModelSceneProps = {
  children?: (props: { is3D?: boolean }) => ReactChildren
  el?: RefObject<HTMLElement>
  is3D?: boolean
  mouse?: { x: number; y: number }
  custom2DPerspective?: { fov: number }
} & GridModelWrapperProps

const DD = {
  ambient: 1,
  spot: 0,
}
const DDD = {
  ambient: 0.3,
  spot: 0.5,
}

const config = { duration: 1000, easing: easeExpOut }

const ShadowDebug = ({ camera, scene }: { camera: MutableRefObject<Camera | undefined>; scene: Scene }) => {
  useViewportHelper(camera, CameraHelper, scene, 'cyan')
  return null
}

/* Animates ambient light */
const GridModelWrapper = ({
  children,
  scale,
  scene,
  debugCamera = false,
  is3D,
  size = 1,
  activeSize = 1,
  rotation = [0, 0, 0],
  activeRotation = [0, 0, 0],
  position = [0, 0, 0],
  activePosition = [0, 0, 0],
}: SceneChildrenType & GridModelWrapperProps) => {
  const mesh = useRef<Group>(null)
  const scaleRatio = scale.width * 3 // random value based on model sizes
  const maxScale = Math.min(scale.width, scale.height)

  // const spot1 = useRef()
  const amb = useRef()
  const spotlight = useRef<SpotLight>(null)
  const shadowCamera = useRef<Camera>()

  useLayoutEffect(() => {
    if (spotlight.current) {
      shadowCamera.current = spotlight.current.shadow.camera
    }
  }, [spotlight])

  const springAmbient = useSpring({
    intensity: is3D ? DDD.ambient : DD.ambient,
    config,
    onChange: () => invalidate(),
  })
  const springSpot = useSpring({
    intensity: is3D ? DDD.spot : DD.spot,
    config,
    onChange: () => invalidate(),
  })

  const springTransform = useSpring({
    scale: is3D ? activeSize * scaleRatio : size * scaleRatio,
    rotation: is3D ? activeRotation : rotation,
    position: is3D ? activePosition : position,
    config,
    onChange: () => invalidate(),
  })

  return (
    <>
      {/* @ts-ignore */}
      <a.group ref={mesh} {...springTransform}>
        <a.ambientLight ref={amb} {...springAmbient} />
        <a.directionalLight
          ref={spotlight}
          color='white'
          castShadow
          position={[maxScale, maxScale, maxScale]}
          {...springSpot}
          shadow-mapSize-width={512}
          shadow-mapSize-height={512}
          shadow-camera-far={maxScale * 7}
          shadow-camera-left={-maxScale * 0.5}
          shadow-camera-right={maxScale * 0.5}
          shadow-camera-top={maxScale * 0.5}
          shadow-camera-bottom={-maxScale * 0.5}
          shadow-bias={-0.001}
          onUpdate={ref => ref.shadow.camera.updateProjectionMatrix()}
        />
        {children}
        {debugCamera && shadowCamera.current && <ShadowDebug camera={shadowCamera} scene={scene} />}
      </a.group>
    </>
  )
}

const PreloadSceneOnMount = (props: { scene: Scene; camera: Camera }) => {
  const { preloadScene } = useScrollRig()
  const { scene, camera } = props
  useEffect(() => preloadScene(scene, camera), [scene, camera, preloadScene])
  return null
}

export default memo(function GridModelScene({
  children,
  el,
  mouse,
  custom2DPerspective,
  ...props
}: GridModelSceneProps) {
  return (
    <ViewportScrollScene el={el} margin={50} priority={1001} scaleMultiplier={0.001} {...props}>
      {(props: SceneChildrenType & GridModelWrapperProps) => {
        // Most objects use the ForcedPerspective, but MultiApp uses a custom FOV instead
        const Perspective = custom2DPerspective ? CustomPerspective : ForcedPerspective
        if (!children) return null
        return (
          <Suspense fallback={null}>
            <Perspective mouse={mouse} {...custom2DPerspective} {...props}>
              <GridModelWrapper {...props}>{children(props)}</GridModelWrapper>
            </Perspective>
            <PreloadSceneOnMount scene={props.scene} camera={props.camera} />
          </Suspense>
        )
      }}
    </ViewportScrollScene>
  )
})
