import React, { useState } from 'react'
import classNames from 'classnames/bind'

import { Body } from 'components/ui/Text'
import ArrowBlock from 'components/ui/ArrowBlock'
import Line from 'components/ui/Line'
import ViewportEnter from 'components/core/ViewportEnter'

import { NumberBlockType } from './NumberBlockTypes'
import * as s from './NumberBlock.module.scss'

const cn = classNames.bind(s)

const NumberBlock = ({ number, isArrowLeft = false, text }: NumberBlockType) => {
  const [isNumberInView, setIsNumberInView] = useState(false)
  const [isTextInView, setIsTextInView] = useState(false)

  return (
    <div className={cn('block', { isArrowLeft, hasText: !!text })}>
      <div className={cn('wrapper')}>
        <ViewportEnter onEnter={() => setIsNumberInView(true)}>
          <div className={cn('number', { isNumberInView })}>{number}</div>
        </ViewportEnter>

        <ArrowBlock isLeft={isArrowLeft} hideOnDesktop={!!text} />

        {!!text && (
          <>
            <Line position={isArrowLeft ? 'right' : 'left'} hideOnMobile duration={0.8} />
            <Line position='bottom' hideOnMobile duration={0.5} delay={0.8} transformOrigin='right top' />
          </>
        )}
      </div>

      {text && (
        <ViewportEnter onEnter={() => setIsTextInView(true)}>
          <div>
            <Body className={cn('text', { isTextInView })}>{text}</Body>
          </div>
        </ViewportEnter>
      )}

      <Line position={isArrowLeft ? 'left' : 'right'} duration={1.2} delay={0.8} />
    </div>
  )
}

export default NumberBlock
