import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import classNames from 'classnames/bind'

import { Display } from 'components/ui/Text'
import GlitchText from 'components/ui/GlitchText'
import Line from 'components/ui/Line'
import Noise from 'components/ui/Noise'
import checkHoverSupport from 'lib/checkHoverSupport'
import { FileWithFluidGatsbyImage } from 'types/GatsbyImage'

import * as s from './Outro.module.scss'

const cn = classNames.bind(s)

type QueryData = {
  gradient: FileWithFluidGatsbyImage
}

const query = graphql`
  query OutroQuery {
    gradient: file(relativePath: { eq: "images/mid-gradient.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Outro = () => {
  const [hasHoverSupport, setHasHoverSupport] = useState(false)

  const { gradient } = useStaticQuery(query) as QueryData

  useEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  return (
    <div className={cn('outro')}>
      <Line className={cn('topLine')} position='top' duration={1.2} delay={0.1} />

      <div className={cn('wrapper')}>
        <div className={cn('gradient')}>
          <GatsbyImage className={cn('stretch')} fluid={gradient.childImageSharp.fluid} />
        </div>
        <Display as='h4' className={cn('outroText')}>
          <span className={cn('glitchText')}>
            {!hasHoverSupport && (
              <GlitchText text='· SPATIAL FIRST ·' secondaryText='· PEOPLE CENTRIC ·' hasBackground={false} blink />
            )}

            {hasHoverSupport && <GlitchText text='SPATIAL FIRST · PEOPLE CENTRIC' hasBackground={false} />}
          </span>
        </Display>

        <Noise absolute />
      </div>

      <Line position='bottom' duration={1.2} delay={0.4} />
    </div>
  )
}

export default Outro
