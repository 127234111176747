// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Link-module--container--7XMmF";
export var wrapper = "Link-module--wrapper--IXSjb";
export var link = "Link-module--link--5cx5C";
export var title = "Link-module--title--2OIH+";
export var lineMobile = "Link-module--lineMobile--GQyCd";
export var lineDesktop = "Link-module--lineDesktop--Pkzht";