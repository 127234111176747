import React, { useRef, useState, forwardRef, Ref, useEffect } from 'react'
import classNames from 'classnames/bind'
import mergeRefs from 'react-merge-refs'

import useRelativePointerPosition from 'lib/useRelativePointerPosition'
import XRButton from 'components/xr/XRButton'
import ViewportEnter from 'components/core/ViewportEnter'
import Line from 'components/ui/Line'
import Arrow from 'components/ui/Arrow'
import ArrowBlock from 'components/ui/ArrowBlock'
import { CaptionText } from 'components/ui/Text'
import useUIContext from 'context/ui'

import * as s from './Main.module.scss'

const cn = classNames.bind(s)

const Main = (
  props: {
    onLogoHover: (hover: boolean) => void
    onMouseMoveOverLogo: (pos: { x: number; y: number }) => void
  },
  logoAreaRef: Ref<HTMLDivElement>,
) => {
  const isLoaderAnimated = useUIContext(s => s.isLoaderAnimated)

  const [logoFadedIn, setLogoFadedIn] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isArrowsInView, setIsArrowsInView] = useState(false)
  const [isModelInView, setIsModelInView] = useState(false)

  const localLogoRef = useRef<HTMLDivElement>()
  const stickyPlaceholderRef = useRef<HTMLDivElement>(null)
  const mouse = useRef({ x: 0, y: 0 }).current

  useRelativePointerPosition(stickyPlaceholderRef.current, relativeMouse => props.onMouseMoveOverLogo(relativeMouse))

  const onMouseMove = (event: React.MouseEvent) => {
    isLoaderAnimated && setIsMouseOver(true)
    logoFadedIn && props.onLogoHover(true)

    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  useEffect(() => {
    const fadeIn = setTimeout(() => setLogoFadedIn(true), 1000)
    return () => clearTimeout(fadeIn)
  }, [isLoaderAnimated])

  return (
    <>
      <div ref={mergeRefs([localLogoRef, logoAreaRef])} className={cn('plutosphere')} />

      <div className={cn('main')}>
        <div className={cn('header')}>
          <CaptionText className={cn('headerLabelHolder')}>Extend Your Reality</CaptionText>
        </div>
        <ViewportEnter onEnter={() => setIsModelInView(true)} onExit={() => setIsModelInView(false)}>
          <div className={cn('modelViewport', { isModelInView })}>
            <XRButton mouse={mouse} isMouseOver={isMouseOver}>
              <div className={cn('bg')} />

              <div
                ref={stickyPlaceholderRef}
                className={cn('stickyPlaceholder')}
                onPointerEnter={() => logoFadedIn && props.onLogoHover(true)}
                onPointerLeave={() => {
                  props.onLogoHover(false)
                  setIsMouseOver(false)
                }}
                onPointerMove={onMouseMove}
              />
            </XRButton>
          </div>
        </ViewportEnter>

        <ViewportEnter onEnter={() => setIsArrowsInView(true)}>
          <div className={cn('arrows', { isArrowsInView })}>
            <div className={cn('arrowsRow')}>
              <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} isContainerSticky animationDelay={0.2} />
              <Arrow rotation={0} mouse={mouse} isMouseOver={isMouseOver} isContainerSticky animationDelay={0.3} />
              <Arrow rotation={0} mouse={mouse} isMouseOver={isMouseOver} isContainerSticky animationDelay={0.4} />
              <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} isContainerSticky animationDelay={0.5} />
            </div>
            <div className={cn('arrowBlockWrapper')}>
              <ArrowBlock hideOnDesktop isLeft />
            </div>
          </div>
        </ViewportEnter>

        <Line position='left' delay={0.65} />
        <Line position='right' delay={1} hideOnDesktop duration={0.5} />
      </div>
    </>
  )
}

export default forwardRef(Main)
