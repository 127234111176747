// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var section = "GridLayout-module--section--jHSEp";
export var row = "GridLayout-module--row--xxZof";
export var reverse = "GridLayout-module--reverse--QtjiV";
export var isSmallHeight = "GridLayout-module--isSmallHeight--J7K8y";
export var group = "GridLayout-module--group--XZlUF";
export var modelWrapper = "GridLayout-module--modelWrapper--RdhCr";
export var modelLineWrapper = "GridLayout-module--modelLineWrapper--1-Jna";
export var model2Line = "GridLayout-module--model2Line--X8W3Q";
export var model4Line = "GridLayout-module--model4Line--tNPUA";