import React, { useState, useLayoutEffect } from 'react'
import { XRSession } from 'three'
import { As, PropsWithAs } from 'reakit-utils/types'
import classNames from 'classnames/bind'

import TrailButton from 'components/ui/TrailButton'
import useUIContext from 'context/ui'

type BaseProps = {
  mouse: { x: number; y: number }
  isMouseOver?: boolean
  fillParent?: boolean
  sessionInitAR?: {
    requiredFeatures?: string[]
    optionalFeatures?: string[]
  }
  sessionInitVR?: {
    requiredFeatures?: string[]
    optionalFeatures?: string[]
  }
}
type BaseType = {
  <Tag extends As>(props: PropsWithAs<BaseProps, Tag>): JSX.Element
}

import * as s from './XRButton.module.scss'
const cn = classNames.bind(s)

const XRButton: BaseType = ({
  children,
  as: Component = 'div',
  mouse,
  fillParent = true,
  isMouseOver = false,
  sessionInitAR = { requiredFeatures: ['hit-test'] },
  sessionInitVR = { optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking', 'high-fixed-foveation-level'] },
  ...props
}) => {
  const [defaultLabel, setDefaultLabel] = useState('Available in XR')
  const [supportsAR, setSupportsAR] = useState(false)
  const [supportsVR, setSupportsVR] = useState(false)
  const [currentSession, setCurrentSession] = useState<XRSession | null>(null)

  async function onSessionStarted(session: XRSession) {
    session.addEventListener('end', onSessionEnded)

    const gl = useUIContext.getState().gl
    if (supportsAR) {
      // we always pick AR if available - it needs local ref space
      gl?.xr.setReferenceSpaceType('local')
      useUIContext.getState().setInAR(true)
    } else {
      useUIContext.getState().setInVR(true)
    }
    await gl?.xr.setSession(session)
  }

  function onSessionEnded(/*event*/) {
    currentSession && currentSession.removeEventListener('end', onSessionEnded)
    setCurrentSession(null)
    window.location.reload()
    return false
  }

  useLayoutEffect(() => {
    if ('xr' in navigator) {
      // @ts-ignore
      navigator.xr.isSessionSupported('immersive-vr').then(function (supported: boolean) {
        setSupportsVR(supported)
      })
      // @ts-ignore
      navigator.xr.isSessionSupported('immersive-ar').then(function (supported: boolean) {
        setSupportsAR(supported)
      })
    } else {
      if (window.isSecureContext === false) {
        setDefaultLabel('WEBXR NEEDS HTTPS')
      }
    }
  }, [])

  function requestSession() {
    if (currentSession === null) {
      // WebXR's requestReferenceSpace only works if the corresponding feature
      // was requested at session creation time. For simplicity, just ask for
      // the interesting ones as optional features, but be aware that the
      // requestReferenceSpace call will fail if it turns out to be unavailable.
      // ('local' is always available for immersive sessions and doesn't need to
      // be requested separately.)

      if (supportsAR) {
        // @ts-ignore
        navigator.xr.requestSession('immersive-ar', sessionInitAR).then(onSessionStarted)
      } else if (supportsVR) {
        // @ts-ignore
        navigator.xr.requestSession('immersive-vr', sessionInitVR).then(onSessionStarted)
      }
      return false
    } else {
      currentSession.end()
    }
  }

  function renderText() {
    if (currentSession) return 'Exit XR'
    else if (supportsAR) {
      return 'Enter AR'
    } else if (supportsVR) {
      return 'Enter VR'
    }
    return defaultLabel
  }

  return (
    <Component
      className={cn('XRButton', { fillParent })}
      {...props}
      onClick={requestSession}
      href={supportsAR || supportsVR ? null : 'https://immersiveweb.dev/'}
      target='_blank'
    >
      {children}
      <TrailButton
        text={renderText()}
        background='linear-gradient(36.38deg, #F969FC 14.52%, #BC01F0 38.16%, #0C01DE 85.21%)'
        invertedText
        mouse={mouse}
        isMouseOver={isMouseOver}
        mobileAnimationDelay={1}
        mobileClass={cn('button')}
      />
    </Component>
  )
}

export default XRButton
