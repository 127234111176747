import React, { useRef, useState, useEffect } from 'react'
import { useRafLoop } from 'react-use'
import lerp from '@14islands/lerp'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'
import checkHoverSupport from 'lib/checkHoverSupport'
import ArrowSvg from 'assets/svg/arrow.react.svg'

import { ArrowType } from './ArrowTypes'
import * as s from './Arrow.module.scss'

const cn = classNames.bind(s)

const Arrow = ({
  rotation,
  mouse,
  isMouseOver,
  isContainerSticky = false,
  animationDelay = 0,
  showOnMobile = false,
}: ArrowType) => {
  const [hasHoverSupport, setHasHoverSupport] = useState(false)
  const [isInView, setIsInView] = useState(false)
  const [hasAnimated, setHasAnimated] = useState(false)

  const angle = useRef({ current: rotation, target: rotation }).current
  const containerRef = useRef<HTMLSpanElement>(null)
  const containerRect = useRef<DOMRect>()
  const initialTop = useRef<number>(0)

  const closestAngle = (from: number, to: number) => from + ((((to - from) % 360) + 540) % 360) - 180

  useEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  useEffect(() => {
    const onResize = () => {
      if (containerRef.current) {
        containerRect.current = containerRef.current.getBoundingClientRect()
        initialTop.current = containerRect.current.top + window.pageYOffset
      }
    }

    const onScroll = () => {
      if (containerRef.current && isInView) {
        containerRect.current = containerRef.current.getBoundingClientRect()
      }
    }

    if (hasHoverSupport) {
      onResize()

      window.addEventListener('resize', onResize)

      if (isContainerSticky) {
        window.addEventListener('scroll', onScroll)
      }
    }

    return () => {
      if (hasHoverSupport) {
        window.removeEventListener('resize', onResize)

        if (isContainerSticky) {
          window.removeEventListener('scroll', onScroll)
        }
      }
    }
  }, [hasHoverSupport, isContainerSticky, isInView])

  useRafLoop(() => {
    if (!hasHoverSupport || !isInView || !containerRef.current || !containerRect.current) return

    if (isMouseOver) {
      const { width, height, left, top } = containerRect.current
      const currentTop = isContainerSticky ? top : initialTop.current - window.pageYOffset

      const arrowCenterPosition = {
        x: left + width / 2,
        y: currentTop + height / 2,
      }

      angle.target = Math.atan2(mouse.x - arrowCenterPosition.x, -(mouse.y - arrowCenterPosition.y)) * (180 / Math.PI)
    } else {
      angle.target = rotation
    }

    angle.target = closestAngle(angle.current, angle.target)

    // @ts-ignore
    angle.current = lerp(angle.current, angle.target, 0.1)

    containerRef.current.style.transform = `rotate(${angle.current}deg)`
  })

  return (
    <ViewportEnter
      onEnter={() => {
        setIsInView(true)
        setHasAnimated(true)
      }}
      onExit={() => setIsInView(false)}
    >
      <span
        ref={containerRef}
        className={cn('container', { showOnMobile, isInView: hasAnimated })}
        style={{ transform: `rotate(${rotation}deg)` }}
      >
        <span className={cn('wrapper')} style={{ transitionDelay: `${animationDelay}s` }}>
          <ArrowSvg />
        </span>
      </span>
    </ViewportEnter>
  )
}

export default Arrow
