import React, { useState, useRef, MouseEvent } from 'react'
import classNames from 'classnames/bind'

import { Body, Headline } from 'components/ui/Text'
import ArrowBlock from 'components/ui/ArrowBlock'
import Caption from 'components/ui/Caption'
import Clickable from 'components/ui/Clickable'
import GlitchText from 'components/ui/GlitchText'
import Line from 'components/ui/Line'
import TrailButton from 'components/ui/TrailButton'
import ViewportEnter from 'components/core/ViewportEnter'

import { ContentType } from './ContentTypes'
import * as s from './Content.module.scss'

const cn = classNames.bind(s)

const Content = ({
  caption,
  title,
  body,
  button,
  isRight = false,
  isSmall = false,
  isLarge = false,
  hasArrow = false,
  hasCaptionLine = true,
}: ContentType) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isInView, setIsInView] = useState(false)

  const mouse = useRef({ x: 0, y: 0 }).current

  const onMouseMove = (event: MouseEvent) => {
    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div
        className={cn('container', { isSmall, isLarge, isInView })}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        onMouseMove={onMouseMove}
      >
        <div className={cn('wrapper')}>
          <Caption hideOnMobile hasLine={hasCaptionLine}>
            {caption}
          </Caption>

          <Line position='top' hideOnDesktop />

          <div className={cn('text')}>
            <Headline className={cn('title')} as='h2'>
              <GlitchText text={title} />
            </Headline>

            <Body className={cn('body')}>{body}</Body>
          </div>

          {button && <TrailButton {...button} isMouseOver={isMouseOver} mouse={mouse} mobileAnimationDelay={0.7} />}

          {hasArrow && (
            <div className={cn('arrowBlockWrapper')}>
              <ArrowBlock isHorizontal />
            </div>
          )}

          <Line position={isRight ? 'left' : 'right'} hideOnMobile duration={1.2} delay={0.4} />
        </div>
        {button && <Clickable to={button?.url || null} target='_blank' className={cn('link')} />}
      </div>
    </ViewportEnter>
  )
}

export default Content
