import React, { useState, useRef, forwardRef } from 'react'
import classNames from 'classnames/bind'
import { useCanvas } from '@14islands/r3f-scroll-rig'

import SphereScene from 'components/xr/SphereScene'
import { Subtitle } from 'components/ui/Text'
import ViewportEnter from 'components/core/ViewportEnter'
import DiamondScene from 'components/xr/DiamondScene'
import SemiSphereScene from 'components/xr/SemiSphereScene'

import CaptionTitle from '../CaptionTitle'
import * as s from './About.module.scss'
const cn = classNames.bind(s)

type onUpdateProps = {
  is3D: boolean
  isInteractedWith?: boolean
}

type SemiSphereProps = {
  className: string
  onUpdate: ({ is3D }: onUpdateProps) => void
}

// eslint-disable-next-line react/display-name
const SemiSphere = forwardRef<HTMLDivElement, SemiSphereProps>(({ className, onUpdate }, ref) => (
  <ViewportEnter onEnter={() => onUpdate({ is3D: true })} onExit={() => onUpdate({ is3D: false })} threshold={1}>
    <div ref={ref} className={cn('semisphere', className)} />
  </ViewportEnter>
))

const SemiSpheresMarkup = () => {
  const rRef = useRef<HTMLDivElement>(null)
  const lRef = useRef<HTMLDivElement>(null)

  const updateR = useCanvas(<SemiSphereScene el={rRef} is3D={false} outlineType='left' modelColor='blue' isIdle />)
  const updateL = useCanvas(
    <SemiSphereScene el={lRef} is3D={false} outlineType='right' modelColor='purple' isFlipped isIdle />,
  )

  return (
    <>
      <SemiSphere ref={rRef} className={'r'} onUpdate={updateR} />
      <SemiSphere ref={lRef} className={'l'} onUpdate={updateL} />
    </>
  )
}

const About = () => {
  const [isMainInView, setIsMainInView] = useState(false)
  const [isAux1InView, setIsAux1InView] = useState(false)
  const [isAux2InView, setIsAux2InView] = useState(false)
  const diamondRef = useRef<HTMLDivElement>(null)
  const sphereRef = useRef<HTMLDivElement>(null)

  const updateDiamond = useCanvas(
    <DiamondScene el={diamondRef} is3D={false} isInView={false} isInteractedWith={false} />,
  )
  const updateSphere = useCanvas(<SphereScene el={sphereRef} is3D={false} isInView={false} isInteractedWith={false} />)

  return (
    <div className={cn('about', { isMainInView, isAux1InView, isAux2InView })}>
      <ViewportEnter onEnter={() => setIsMainInView(true)}>
        <div>
          <div className={cn('header')}>
            <CaptionTitle delay={1.2}>About Pluto</CaptionTitle>
          </div>
          <div className={cn('content')}>
            <Subtitle>
              With Pluto people can access powerful XR applications, and use them with anyone, no matter of their
              physical location.
            </Subtitle>
          </div>
        </div>
      </ViewportEnter>
      <ViewportEnter
        onEnter={() => {
          setIsAux1InView(true)
          updateDiamond({ isInView: true })
          updateSphere({ isInView: true })
        }}
      >
        <div className={cn('aux1')}>
          <div
            className={cn('diamond')}
            ref={diamondRef}
            onPointerEnter={() => {
              updateDiamond({ is3D: true, isInteractedWith: true })
            }}
            onPointerLeave={() => {
              updateDiamond({ is3D: false })
            }}
          />
          <div
            className={cn('sphere')}
            ref={sphereRef}
            onPointerEnter={() => {
              updateSphere({ is3D: true, isInteractedWith: true })
            }}
            onPointerLeave={() => {
              updateSphere({ is3D: false })
            }}
          />
          <div className={cn('line')} />
        </div>
      </ViewportEnter>
      <ViewportEnter onEnter={() => setIsAux2InView(true)}>
        <div className={cn('aux2')} />
      </ViewportEnter>
      <div className={cn('semispheresMobile')}>
        <SemiSpheresMarkup />
      </div>
    </div>
  )
}

export default About
