import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'

import { GlitchTextType } from './GlitchTextTypes'
import * as s from './GlitchText.module.scss'

const cn = classNames.bind(s)

const GlitchText = ({
  className,
  text,
  secondaryText,
  animationDuration = 1,
  hasBackground = true,
  blink = false,
}: GlitchTextType) => {
  const [isInView, setIsInView] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [targetText, setTargetText] = useState(text)

  const animate = useCallback(() => {
    setIsAnimating(true)

    setTimeout(() => {
      setIsAnimating(false)
    }, animationDuration * 1000)
  }, [animationDuration])

  useEffect(() => {
    if (isInView) {
      animate()
    }
  }, [isInView, animate])

  useEffect(() => {
    if (isInView && secondaryText) {
      setTimeout(() => {
        animate()
        setTargetText(targetText === text ? secondaryText : text)
      }, 2000)
    }
  }, [isInView, targetText, text, secondaryText, animate])

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <span className={classNames(cn('container', className, { isAnimating, hasBackground, blink }))}>
        <span className={classNames(cn('glitch'))}>
          <span
            className={classNames(cn('glitchEffect', 'before'))}
            style={{ animationDuration: `${animationDuration}s` }}
          >
            {targetText}
          </span>

          {targetText}

          <span
            className={classNames(cn('glitchEffect', 'after'))}
            style={{ animationDuration: `${animationDuration}s` }}
          >
            {targetText}
          </span>
        </span>
      </span>
    </ViewportEnter>
  )
}

export default GlitchText
