import React, { useRef, RefObject, useEffect, Suspense, useState, memo } from 'react'
import { invalidate } from '@react-three/fiber'
import { a, useSpring } from '@react-spring/three'
import { Group, Scene, Camera } from 'three'
import { useScrollRig } from '@14islands/r3f-scroll-rig'
import { easeExpOut } from 'd3-ease'
import { useWindowSize } from '@react-hook/window-size'

import useUIContext from 'context/ui'
import ForcedPerspective from 'components/xr/ForcedPerspective'
import type { SceneChildrenType } from 'types/ScrollRig'
import { PlutoLogo } from 'components/xr/Objects'

import { StickyViewportScrollScene } from './StickyViewportScrollScene'

type Props = {
  el: RefObject<HTMLElement>
  footerEl?: RefObject<HTMLElement>
  is3D: boolean
  mouseRelative?: { x: number; y: number }
  isMinimized?: boolean
  isClosed?: boolean
}

const DD = {
  ambient: 1,
  spot: 0,
}
const DDD = {
  ambient: 0.05,
  spot: 0.1,
}

const config = { duration: 1000, easing: easeExpOut }

const PlutoLogoWithScroll = ({ isClosed, isMinimized, scale, is3D, scene }: SceneChildrenType & Props) => {
  const mesh = useRef<Group>(null)
  const size = scale.height * 2.2
  const isLoaderAnimated = useUIContext(s => s.isLoaderAnimated)
  const [visible, setVisible] = useState(false)
  const [width] = useWindowSize({ wait: 200 })

  const amb = useRef()

  const springAmbient = useSpring({
    intensity: is3D ? DDD.ambient : DD.ambient,
    config,
  })
  const springSpot = useSpring({
    intensity: is3D ? DDD.spot : DD.spot,
    config,
  })

  const springMesh = useSpring({
    scale: isMinimized ? size * (width > 600 ? 0.2 : 0.33) : is3D ? size * 1.2 : size,
    position: [
      isMinimized ? size * 0.002 : 0,
      isMinimized ? size * (width > 1000 ? 0.22 : width > 600 ? 0.2 : 0.19) : is3D ? size * 0.06 : size * 0.05,
      0,
    ],
    config,
    onChange: () => invalidate(),
  })

  useEffect(() => {
    if (!isLoaderAnimated) return
    setVisible(true)
  }, [isLoaderAnimated, setVisible])

  return (
    <>
      {/* @ts-ignore */}
      <a.group ref={mesh} {...springMesh}>
        <a.ambientLight ref={amb} {...springAmbient} />
        <a.directionalLight
          color='white'
          castShadow
          position={[scale.height, scale.height, scale.height]}
          {...springSpot}
          shadow-mapSize-width={512}
          shadow-mapSize-height={512}
          shadow-camera-near={0}
          shadow-camera-far={scale.height * 5}
          shadow-camera-left={-scale.height * 1}
          shadow-camera-right={scale.height * 1}
          shadow-camera-top={scale.height * 1}
          shadow-camera-bottom={-scale.height * 1}
          shadow-bias={-0.002}
          onUpdate={ref => ref.shadow.camera.updateProjectionMatrix()}
        />
        <PlutoLogo
          draggable={false}
          scene={scene}
          visible={visible}
          makeSelected={is3D && !isClosed}
          makeFlat={!is3D}
          makeClosed={isClosed}
        />
      </a.group>
    </>
  )
}

const PreloadSceneOnMount = (props: { scene: Scene; camera: Camera }) => {
  const { preloadScene } = useScrollRig()
  const { scene, camera } = props
  useEffect(() => preloadScene(scene, camera), [scene, camera, preloadScene])
  return null
}

const PlutoLogoScene = ({ el, footerEl, is3D, mouseRelative, isClosed, isMinimized }: Props) => {
  return (
    // @ts-ignore
    <StickyViewportScrollScene
      el={el}
      footerEl={footerEl}
      debug={false}
      margin={100}
      scaleMultiplier={0.001}
      priority={1002}
    >
      {(props: SceneChildrenType) => {
        return (
          <Suspense fallback={null}>
            <ForcedPerspective mouseRelative={mouseRelative} is3D={is3D} {...props}>
              <PlutoLogoWithScroll is3D={is3D} isClosed={isClosed} isMinimized={isMinimized} {...props} />
            </ForcedPerspective>
            <PreloadSceneOnMount scene={props.scene} camera={props.camera} />
          </Suspense>
        )
      }}
    </StickyViewportScrollScene>
  )
}

export default memo(PlutoLogoScene)
