import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { Group, Mesh } from 'three'
import { GLTF } from 'three-stdlib'
import { a, useSpring } from '@react-spring/three'

import model from 'assets/semisphere/model.glb'
import blueGradient from 'assets/images/model-gradients/blue.png'
import purpleGradient from 'assets/images/model-gradients/purple.png'

useTexture.preload(blueGradient)
useTexture.preload(purpleGradient)

useGLTF.preload(model, false)

const sizeFactor = 0.99 //based on model size

type GLTFResult = GLTF & {
  nodes: {
    [geometry: string]: THREE.Mesh
  }
  materials: {
    [material: string]: THREE.Material
  }
}

type SemiSphereProps = {
  isSceneActive: boolean
  size: number
  color: 'white' | 'purple' | 'blue'
  isFlipped?: boolean
}

const materialsConfig = {
  white: {
    metalness: 0.10000000149011612,
    roughness: 0.4858267903327942,
  },
  blue: {
    metalness: 0.14000000059604645,
    roughness: 0.20000000298023224,
  },
  purple: {
    metalness: 0.14000000059604645,
    roughness: 0.20000000298023224,
  },
}

const SemiSphere = ({ size, color, isSceneActive = false, isFlipped = false, ...props }: SemiSphereProps) => {
  const { nodes } = useGLTF(model, false) as GLTFResult
  const group = useRef<Group>()
  const mesh = useRef<Mesh>()
  const gKey = Object.keys(nodes)[1] // based on the nodes object structure
  const blueTexture = useTexture(blueGradient)
  const purpleTexture = useTexture(purpleGradient)
  const texture = color === 'white' ? null : color === 'blue' ? blueTexture : purpleTexture

  const materialSpring = useSpring({
    'material-opacity': isSceneActive ? 1 : 0,
    delay: isSceneActive ? 0 : 2500,
    config: {
      duration: 300,
    },
  })

  useSpring({
    rotationY: isSceneActive ? 0 : isFlipped ? Math.PI * 0.5 : Math.PI * -0.5,
    delay: isSceneActive ? 300 : 2000,
    onChange: spring => {
      if (!group || !group.current) return
      group.current.rotation.y = spring.value.rotationY
    },
  })

  return (
    <>
      <a.group
        ref={group}
        {...props}
        dispose={null}
        rotation-y={isFlipped ? Math.PI * 0.5 : Math.PI * -0.5}
        position-x={size * (isFlipped ? -0.5 : 0.5)}
      >
        <a.mesh
          ref={mesh}
          castShadow
          receiveShadow
          geometry={nodes[gKey].geometry}
          scale={sizeFactor * size}
          rotation={[-Math.PI, isFlipped ? Math.PI : Math.PI * 1.5, 0]}
          {...materialSpring}
        >
          <meshStandardMaterial
            transparent
            opacity={0}
            map={texture}
            roughness={materialsConfig[color].roughness}
            metalness={materialsConfig[color].metalness}
          />
        </a.mesh>
      </a.group>
    </>
  )
}

export default SemiSphere
