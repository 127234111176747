// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var logo = "Logo-module--logo--W-F7R";
export var isInView = "Logo-module--isInView--g4W+5";
export var inner = "Logo-module--inner--ZMPTa";
export var aux = "Logo-module--aux--0a9VN";
export var semisphere = "Logo-module--semisphere--UJFUP";
export var r = "Logo-module--r--+ZD0R";
export var cr = "Logo-module--cr--UKXTQ";
export var cl = "Logo-module--cl--mYjj4";
export var l = "Logo-module--l--Ae4Wy";