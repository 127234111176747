// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "ArrowBlock-module--container--Wzhgu";
export var isHorizontal = "ArrowBlock-module--isHorizontal--b1YF4";
export var hideOnDesktop = "ArrowBlock-module--hideOnDesktop--CTD7l";
export var arrow = "ArrowBlock-module--arrow--rMyVh";
export var isInView = "ArrowBlock-module--isInView--VYrqr";
export var isLeft = "ArrowBlock-module--isLeft--wzJU2";