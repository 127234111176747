import { useEffect, useCallback, ReactElement } from 'react'
import { useSpring } from '@react-spring/three'
import { MathUtils, Vector3 } from 'three'
import { easeExpOut } from 'd3-ease'
import { useFrame, invalidate, useThree } from '@react-three/fiber'
import lerp from '@14islands/lerp'

import type { SceneChildrenType } from 'types/ScrollRig'

export function distanceForFOV(height: number, fov: number) {
  return height / 2 / Math.tan(MathUtils.degToRad(fov / 2))
}

export function fovForDistance(height: number, distance: number) {
  return 2 * (180 / Math.PI) * Math.atan(height / (2 * distance))
}

const cameraTarget = new Vector3(0, 0, 0)

type Props = {
  is3D?: boolean
  children: ReactElement
  mouse?: { x: number; y: number }
  parallaxAmount?: number
  fov?: number
  defaultFov?: number
}

export default function CustomPerspective({
  is3D = false,
  scale,
  camera,
  children,
  margin,
  scrollState,
  mouse,
  parallaxAmount = 0.5,
  fov = 100,
  defaultFov = 50,
  scene,
}: Props & SceneChildrenType) {
  const { scene: defaultScene } = useThree()
  const updateCamera = useCallback(
    fov => {
      camera.fov = fov
      camera.position.z = distanceForFOV(scale.height + margin * 2 * scale.multiplier, fov)
      camera.far = camera.position.z + scale.height * 0.5
      camera.near = Math.max(0.00001, camera.position.z - scale.height * 0.5)
      camera.updateProjectionMatrix()
    },
    [scale, camera, margin],
  )

  useFrame((_, delta) => {
    if (scrollState.inViewport && is3D) invalidate()
    if (!mouse) return
    let x, y
    if (mouse.x === -1 && mouse.y === -1) {
      // preset position for no-hover
      x = 0.5
      y = -0.5
    } else {
      x = ((mouse.x - scrollState.left) / scrollState.width - 0.5) * 2
      y = ((mouse.y - scrollState.top + window.pageYOffset) / scrollState.height - 0.5) * 2
    }
    const targetX = is3D ? x * scale.width * parallaxAmount : 0
    const targetY = is3D ? y * scale.height * parallaxAmount : 0
    camera.position.x = lerp(camera.position.x, targetX, 0.1, delta)
    camera.position.y = lerp(camera.position.y, -targetY, 0.1, delta)
    cameraTarget.x = camera.position.x * 0.1
    cameraTarget.y = camera.position.y * 0.1
    camera.lookAt(cameraTarget)
  })

  useSpring({
    fov: is3D ? defaultFov : fov,
    config: { duration: 1000, easing: easeExpOut },
    onChange: v => {
      updateCamera(v.value.fov)
    },
  })

  useEffect(() => {
    updateCamera(is3D ? defaultFov : fov)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCamera, scale])

  // borrow envmap from global scene (since this should be in a viewport)
  useEffect(() => {
    scene.environment = defaultScene.environment
  }, [scene, defaultScene.environment])

  return children
}
