import React, { useRef } from 'react'
import { useLoader } from '@react-three/fiber'
import { TextureLoader, LinearFilter } from 'three'
import { a, useSpring } from '@react-spring/three'

import useUIContext from 'context/ui'
import outlineLeft from 'assets/semisphere/outlineLeft.png'
import outlineRight from 'assets/semisphere/outlineRight.png'

const outline = {
  left: outlineLeft,
  right: outlineRight,
}

type OutlineProps = {
  isSceneActive: boolean
  width: number
  height: number
  type: 'left' | 'right'
  isIdle: boolean
  isInteractedWith: boolean
  delayIndex: number
}

const Outline = ({
  isSceneActive = false,
  width,
  height,
  type,
  isIdle = false,
  isInteractedWith = false,
  delayIndex,
}: OutlineProps) => {
  const mesh = useRef<THREE.Mesh>(null)
  const texture = useLoader(TextureLoader, outline[type])
  const isLoaderAnimated = useUIContext(s => s.isLoaderAnimated)

  texture.minFilter = LinearFilter

  const { opacity } = useSpring({
    opacity: isLoaderAnimated ? (isIdle ? 1 : isSceneActive ? 0 : 1) : 0,
    delay: !isInteractedWith ? 1000 + delayIndex * 50 : isSceneActive ? 0 : 2500,
    config: {
      duration: 300,
    },
  })

  return (
    <a.mesh ref={mesh} renderOrder={1} material-opacity={opacity}>
      <planeBufferGeometry attach='geometry' args={[width, height]} />
      <meshBasicMaterial attach='material' map={texture} transparent={true} depthTest={false} />
    </a.mesh>
  )
}

export default Outline
