import React, { useState } from 'react'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'

import * as s from './XR.module.scss'
const cn = classNames.bind(s)

const XR = () => {
  const [isInView, setIsInView] = useState(false)
  const [isActive, setIsActive] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('xr', { isInView, isActive })}>
        <div className={cn('x')} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
          <div className={cn('inner')}>
            <svg viewBox='0 0 104 121' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M64.4738 61.1001L38.847 61.1001L1.81008 120L25.5525 120L51.4067 78.4879L51.8298 77.8086L52.2548 78.4867L78.2766 120H102.004L64.4738 61.1001ZM38.7412 60.4445V60.1001L64.7412 60.1001V60.3396L65.0497 60.1431L103.337 120.231L103.827 121H102.915H77.9999H77.7232L77.5762 120.765L51.8325 79.6958L26.2545 120.764L26.1078 121H25.8301L0.905042 121H0L0.481769 120.234L38.2659 60.1456L38.7412 60.4445Z'
                fill='white'
                className={cn('aWhite')}
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.905271 0H0.000228882L0.481998 0.766159L38.2661 60.8543L38.7414 60.5555V61.1001L64.7414 61.1001V60.6603L65.05 60.8569L103.337 0.768684L103.827 0H102.915H78.0001H77.7234L77.5764 0.234443L51.8327 41.3041L26.2548 0.235669L26.108 0H25.8304H0.905271ZM64.3464 60.1001L102.004 1H78.2768L52.255 42.5133L51.83 43.1913L51.4069 42.512L25.5527 1H1.81031L38.9731 60.1001L64.3464 60.1001Z'
                fill='white'
                className={cn('vWhite')}
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.905271 0H0.000228882L0.481998 0.766159L38.2661 60.8543L38.7414 60.5555V61.1001L64.7414 61.1001V60.6603L65.05 60.8569L103.337 0.768684L103.827 0H102.915H78.0001H77.7234L77.5764 0.234443L51.8327 41.3041L26.2548 0.235669L26.108 0H25.8304H0.905271ZM64.3464 60.1001L102.004 1H78.2768L52.255 42.5133L51.83 43.1913L51.4069 42.512L25.5527 1H1.81031L38.9731 60.1001L64.3464 60.1001Z'
                fill='url(#paint0_linear)'
                className={cn('vGradient')}
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M64.4738 61.1001L38.847 61.1001L1.81008 120L25.5525 120L51.4067 78.4879L51.8298 77.8086L52.2548 78.4867L78.2766 120H102.004L64.4738 61.1001ZM38.7412 60.4445V60.1001L64.7412 60.1001V60.3396L65.0497 60.1431L103.337 120.231L103.827 121H102.915H77.9999H77.7232L77.5762 120.765L51.8325 79.6958L26.2545 120.764L26.1078 121H25.8301L0.905042 121H0L0.481769 120.234L38.2659 60.1456L38.7412 60.4445Z'
                fill='url(#paint1_linear)'
                className={cn('aGradient')}
              />
              <defs>
                <linearGradient
                  id='paint0_linear'
                  x1='24.462'
                  y1='56.3017'
                  x2='51.8451'
                  y2='-6.84853'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop offset='0' stopColor='#F969FC' />
                  <stop offset='0.334433' stopColor='#BC01F0' />
                  <stop offset='1' stopColor='#0C01DE' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear'
                  x1='24.4618'
                  y1='116.217'
                  x2='51.6938'
                  y2='53.2088'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop offset='0' stopColor='#F969FC' />
                  <stop offset='0.334433' stopColor='#BC01F0' />
                  <stop offset='1' stopColor='#0C01DE' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className={cn('r')} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
          <div className={cn('inner')}>
            <svg viewBox='0 0 91 116' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M84.92 73.24H70.2968L89.9768 115H68.248L48.408 73.24L21.08 73.24L21.08 115H1L1 1L84.92 1L84.92 73.24ZM66.12 20.28L21.08 20.28L21.08 55.72L66.12 55.72L66.12 20.28Z'
                stroke='white'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M84.92 73.24H70.2968L89.9768 115H68.248L48.408 73.24L21.08 73.24L21.08 115H1L1 1L84.92 1L84.92 73.24ZM66.12 20.28L21.08 20.28L21.08 55.72L66.12 55.72L66.12 20.28Z'
                stroke='url(#paint2_linear)'
                className={cn('rGradient')}
              />
              <defs>
                <linearGradient
                  id='paint2_linear'
                  x1='21.9631'
                  y1='106.047'
                  x2='91.8369'
                  y2='32.0336'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop offset='0' stopColor='#F969FC' />
                  <stop offset='0.334433' stopColor='#BC01F0' />
                  <stop offset='1' stopColor='#0C01DE' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </ViewportEnter>
  )
}

export default XR
