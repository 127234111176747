import React from 'react'
import classNames from 'classnames/bind'

import Line from 'components/ui/Line'

import * as s from './Side.module.scss'
const cn = classNames.bind(s)

const Side = () => {
  return (
    <div className={cn('side')}>
      <Line position={'right'} delay={0.6} duration={2} />
    </div>
  )
}

export default Side
