import { useCallback, useRef, useLayoutEffect } from 'react'
import { MathUtils } from 'three'
import { useWindowSize } from '@react-hook/window-size'

const useRelativePointerPosition = (
  el: HTMLDivElement | undefined | null,
  onMove: (pos: { x: number; y: number }) => void,
) => {
  const mouse = useRef({ x: 0, y: 0 }).current
  const logoRect = useRef({ top: 0, left: 0, width: 0, height: 0 }).current
  const [width, height] = useWindowSize()

  const onPointerMove = useCallback(
    (event: MouseEvent) => {
      mouse.x = MathUtils.clamp(((event.clientX - logoRect.left) / logoRect.width - 0.5) * 2, -1, 1)
      mouse.y = MathUtils.clamp(
        ((event.clientY - logoRect.top + window.pageYOffset) / logoRect.height - 0.5) * 2,
        -1,
        1,
      )
      onMove && onMove(mouse)
    },
    [mouse, logoRect, onMove],
  )

  useLayoutEffect(() => {
    const domEl = el
    if (domEl) {
      const rect = domEl.getBoundingClientRect()
      logoRect.top = rect.top + window.pageYOffset
      logoRect.left = rect.left
      logoRect.width = rect.width
      logoRect.height = rect.height
      domEl?.addEventListener('pointermove', onPointerMove)
    }
    return () => domEl?.removeEventListener('pointermove', onPointerMove)
  }, [el, onPointerMove, width, height, logoRect])

  return mouse
}

export default useRelativePointerPosition
