// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var stars = "Stars-module--stars--C22Ys";
export var isInView = "Stars-module--isInView--abBFt";
export var fadeIn = "Stars-module--fadeIn--y2MCe";
export var image = "Stars-module--image--y9vF6";
export var mobile = "Stars-module--mobile--krqS2";
export var desktop = "Stars-module--desktop--tgPKq";