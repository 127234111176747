import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'

import ViewportEnter from 'components/core/ViewportEnter'
import { FileWithFluidGatsbyImage } from 'types/GatsbyImage'

import { StarsType } from './StarsTypes'
import * as s from './Stars.module.scss'
const breakpoint = parseInt(s.breakpointMedium)

const cn = classNames.bind(s)

type QueryData = {
  starsDesktop: FileWithFluidGatsbyImage
  starsMobile: FileWithFluidGatsbyImage
}

const query = graphql`
  query StarsQuery {
    starsDesktop: file(relativePath: { eq: "images/stars-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    starsMobile: file(relativePath: { eq: "images/stars-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Stars = ({ className, animationDuration = 1.5, animationDelay = 0.5 }: StarsType) => {
  const data = useStaticQuery(query) as QueryData
  const [width] = useWindowSize({ wait: 200 })
  const [isDesktop, setIsDesktop] = useState(false)
  const [isInView, setIsInView] = useState(false)

  useEffect(() => setIsDesktop(width > breakpoint), [width])

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div
        className={cn('stars', className, { isInView })}
        style={{ animationDuration: `${animationDuration}s`, animationDelay: `${animationDelay}s` }}
      >
        <GatsbyImage fluid={data[isDesktop ? 'starsDesktop' : 'starsMobile'].childImageSharp.fluid} />
      </div>
    </ViewportEnter>
  )
}

export default Stars
