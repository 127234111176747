import React, { RefObject, memo } from 'react'
import { ScrollScene } from '@14islands/r3f-scroll-rig'

import type { SceneChildrenType } from 'types/ScrollRig'
import Diamond from 'components/xr/Diamond/'
import Outline from 'components/xr/Diamond/Outline'

type GroupProps = SceneChildrenType & {
  isSceneActive: boolean
  isInView: boolean
  isIdle?: boolean
  isInteractedWith?: boolean
}

const Group = ({ isSceneActive, isInView, isIdle, isInteractedWith, ...props }: GroupProps) => {
  return (
    <>
      <group>
        {!isIdle && (
          <Diamond
            isSceneActive={isSceneActive}
            size={props.scale.width}
            //@ts-ignore
            multiplier={props.scale.multiplier}
          />
        )}
        <Outline
          isSceneActive={isSceneActive}
          size={props.scale.width}
          isInView={isInView}
          isInteractedWith={isInteractedWith}
        />
      </group>
    </>
  )
}

type SceneProps = {
  el: RefObject<HTMLElement>
  is3D: boolean
  isInView: boolean
  isIdle?: boolean
  isInteractedWith?: boolean
}

const DiamondScene = ({ el, is3D, isInView, isIdle, isInteractedWith }: SceneProps) => {
  return (
    <ScrollScene el={el} margin={100} debug={false}>
      {(props: SceneChildrenType) => (
        <>
          <Group
            isSceneActive={is3D}
            isInView={isInView}
            isIdle={isIdle}
            isInteractedWith={isInteractedWith}
            {...props}
          />
        </>
      )}
    </ScrollScene>
  )
}

export default memo(DiamondScene)
