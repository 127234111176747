import React, { useState } from 'react'
import classNames from 'classnames/bind'

import { CaptionText } from 'components/ui/Text'
import Line from 'components/ui/Line'
import ViewportEnter from 'components/core/ViewportEnter'

import { CaptionType } from './CaptionTypes'
import * as s from './Caption.module.scss'

const cn = classNames.bind(s)

const Caption = ({
  children,
  className,
  hasLine = true,
  hideOnMobile,
  hideOnDesktop,
  lineAnimationDuration = 0.7,
  lineAnimationDelay = 0.4,
}: CaptionType) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('container', className, { hideOnMobile, hideOnDesktop, isInView })}>
        <CaptionText as='span' className={cn('text')}>
          {children}

          {hasLine && <Line position='bottom' duration={lineAnimationDuration} delay={lineAnimationDelay} />}
        </CaptionText>
      </div>
    </ViewportEnter>
  )
}

export default Caption
