// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "GlitchText-module--container--8YTRy";
export var isAnimating = "GlitchText-module--isAnimating--01hVw";
export var blink = "GlitchText-module--blink--Tocmo";
export var blinkAnimation = "GlitchText-module--blink-animation--P6ywx";
export var glitch = "GlitchText-module--glitch--5K-U3";
export var glitchEffect = "GlitchText-module--glitchEffect--5G+wu";
export var hasBackground = "GlitchText-module--hasBackground--39dJq";
export var before = "GlitchText-module--before--KChCD";
export var glitchAnimation1 = "GlitchText-module--glitch-animation-1--TNe3-";
export var after = "GlitchText-module--after--CwaD2";
export var glitchAnimation2 = "GlitchText-module--glitch-animation-2--ftgPr";