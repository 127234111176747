import React, { useState, useRef, MouseEvent } from 'react'
import classNames from 'classnames/bind'

import { Headline } from 'components/ui/Text'
import Clickable from 'components/ui/Clickable'
import GlitchText from 'components/ui/GlitchText'
import TrailButton from 'components/ui/TrailButton'
import ViewportEnter from 'components/core/ViewportEnter'

import CaptionTitle from '../CaptionTitle'
import * as s from './CTA.module.scss'
const cn = classNames.bind(s)

const url = 'https://plutovr.applytojob.com/apply'

const CTA = () => {
  const [isInView, setIsInView] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const mouse = useRef({ x: 0, y: 0 }).current

  const onMouseMove = (event: MouseEvent) => {
    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  return (
    <ViewportEnter onEnter={() => setIsInView(true)} threshold={0}>
      <div className={cn('cta', { isInView })}>
        <div className={cn('header')}>
          <CaptionTitle delay={1.2}>We&apos;re hiring</CaptionTitle>
        </div>

        <div className={cn('content')}>
          <Headline>
            <GlitchText
              text='Be part of booting up the next reality of computing.'
              animationDuration={1.75}
              hasBackground={false}
            />
          </Headline>

          <TrailButton
            text='Join Us'
            background='linear-gradient(36.38deg, #F969FC 14.52%, #BC01F0 38.16%, #0C01DE 85.21%)'
            invertedText
            mouse={mouse}
            isMouseOver={isMouseOver}
            url={url}
            mobileClass={cn('button')}
            mobileAnimationDelay={1.5}
          />
        </div>
        <Clickable
          to={url}
          className={cn('link')}
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          onMouseMove={onMouseMove}
        >
          <span>{url}</span>
        </Clickable>
      </div>
    </ViewportEnter>
  )
}

export default CTA
