// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Arrows-module--container--kOmni";
export var pArea = "Arrows-module--pArea--QZjaF";
export var plutoLogoPlaceholder = "Arrows-module--plutoLogoPlaceholder--t2fTz";
export var rows = "Arrows-module--rows--9vcfX";
export var row = "Arrows-module--row--qz5Qy";