import React, { ReactElement, useRef, useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useCanvas } from '@14islands/r3f-scroll-rig'

import GridModelScene, { GridModelSceneProps } from 'components/xr/GridModelScene'
import DiamondGrid from 'components/ui/DiamondGrid'
import ViewportEnter from 'components/core/ViewportEnter'
import checkHoverSupport from 'lib/checkHoverSupport'

import * as s from './Model.module.scss'

type ModelProps = {
  Model: (props: { makeFlat?: boolean; spin?: boolean }) => ReactElement
  isSquare?: boolean
}

const cn = classNames.bind(s)
const Model = (props: ModelProps & GridModelSceneProps) => {
  const [hasHoverSupport, setHasHoverSupport] = useState(false)

  const el = useRef<HTMLDivElement>(null)
  const mouse = useRef({ x: -1, y: -1 }).current // avoid mouse parallax on mobile by using -1 as default
  const { Model, isSquare, ...extraProps } = props

  const updateMesh = useCanvas(
    <GridModelScene mouse={mouse} el={el} {...extraProps}>
      {/* @ts-ignore */}
      {({ is3D }) => {
        return <Model makeFlat={!is3D} spin={is3D} />
      }}
    </GridModelScene>,
  )

  useEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  return (
    <ViewportEnter
      threshold={1}
      onEnter={() => !hasHoverSupport && updateMesh({ is3D: true })}
      onExit={() => !hasHoverSupport && updateMesh({ is3D: false })}
    >
      <div
        className={cn('container')}
        onPointerEnter={() => hasHoverSupport && updateMesh({ is3D: true })}
        onPointerLeave={() => hasHoverSupport && updateMesh({ is3D: false })}
        onMouseMove={e => {
          if (hasHoverSupport) {
            mouse.x = e.clientX
            mouse.y = e.clientY
          }
        }}
      >
        <DiamondGrid />
        <div ref={el} className={cn(s.modelStrokeArt, { isSquare })} />
        <div ref={el} className={s.modelPlaceholder} />
      </div>
    </ViewportEnter>
  )
}

export default Model
