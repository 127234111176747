// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var cta = "CTA-module--cta--r2ClN";
export var isInView = "CTA-module--isInView--1ucPr";
export var header = "CTA-module--header--1TWcd";
export var content = "CTA-module--content--seda+";
export var button = "CTA-module--button--13PhP";
export var link = "CTA-module--link--jG9ID";