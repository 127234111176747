// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var plutosphere = "Main-module--plutosphere--uLYeN";
export var main = "Main-module--main--4Cf9F";
export var header = "Main-module--header--wloPe";
export var modelViewport = "Main-module--modelViewport--iAgjn";
export var stickyPlaceholder = "Main-module--stickyPlaceholder--7fZGE";
export var bg = "Main-module--bg--sZjqE";
export var isModelInView = "Main-module--isModelInView--Og0LB";
export var arrows = "Main-module--arrows--p5M0m";
export var isArrowsInView = "Main-module--isArrowsInView--GENzb";
export var arrowBlockWrapper = "Main-module--arrowBlockWrapper--GNGpA";
export var arrowsRow = "Main-module--arrowsRow--x4UJt";
export var headerLabelHolder = "Main-module--headerLabelHolder--hZnGv";