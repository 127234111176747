// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var outro = "Outro-module--outro--Ei8f5";
export var wrapper = "Outro-module--wrapper--b+zsJ";
export var gradient = "Outro-module--gradient--K2yOR";
export var topLine = "Outro-module--topLine--BRMvC";
export var stretch = "Outro-module--stretch--8sH-T";
export var outroText = "Outro-module--outroText--o6zC-";
export var glitchText = "Outro-module--glitchText--z-Cr3";