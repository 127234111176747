import React from 'react'
import { Scene, Object3D } from 'three'
import { useFrame } from '@react-three/fiber'

type Helper = Object3D & {
  update: () => void
}
export default function useViewportHelper<T>(
  object3D: React.MutableRefObject<Object3D | undefined>,
  proto: T,
  scene: Scene,
  ...args: unknown[]
) {
  const helper = React.useRef<Helper>()

  React.useEffect(() => {
    if (proto && object3D.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      helper.current = new (proto as any)(object3D.current, ...args)
      if (helper.current) {
        scene.add(helper.current)
      }
    }

    return () => {
      if (helper.current) {
        scene.remove(helper.current)
      }
    }
  }, [scene, proto, object3D, args])

  useFrame(() => {
    if (helper.current) {
      helper.current.update()
    }
  })

  return helper
}
