import React, { useRef, useState, MouseEvent } from 'react'
import classNames from 'classnames/bind'

import { Subtitle } from 'components/ui/Text'
import Caption from 'components/ui/Caption'
import Clickable from 'components/ui/Clickable'
import Line from 'components/ui/Line'
import TrailButton from 'components/ui/TrailButton'
import ViewportEnter from 'components/core/ViewportEnter'

import { LinkType } from './LinkTypes'
import * as s from './Link.module.scss'

const cn = classNames.bind(s)

const Link = ({ caption, title, button, hasLine, captionLineAnimationDelay }: LinkType) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isInView, setIsInView] = useState(false)

  const mouse = useRef({ x: 0, y: 0 }).current

  const onMouseMove = (event: MouseEvent) => {
    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div
        className={cn('container', { isInView })}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        onMouseMove={onMouseMove}
      >
        <div className={cn('wrapper')}>
          <Caption lineAnimationDelay={captionLineAnimationDelay}>{caption}</Caption>

          <Subtitle className={cn('title')}>{title}</Subtitle>

          <TrailButton
            {...button}
            background='linear-gradient(36.38deg, #f969fc 14.52%, #bc01f0 38.16%, #0c01de 85.21%)'
            invertedText
            mouse={mouse}
            isMouseOver={isMouseOver}
            mobileClass={cn('button')}
            mobileAnimationDelay={0.8}
          />

          {hasLine && (
            <>
              <div className={cn('lineMobile')}>
                <Line position='bottom' delay={0.4} />
              </div>

              <div className={cn('lineDesktop')}>
                <Line position='right' delay={0.6} />
              </div>
            </>
          )}
        </div>

        <Clickable to={button?.url} target='_blank' className={cn('link')} />
      </div>
    </ViewportEnter>
  )
}

export default Link
