import React, { useState, useRef, forwardRef, memo } from 'react'
import classNames from 'classnames/bind'
import { useCanvas } from '@14islands/r3f-scroll-rig'

import ViewportEnter from 'components/core/ViewportEnter'
import SemiSphereScene from 'components/xr/SemiSphereScene'
import useUIContext from 'context/ui'

import SVG from './SVG'
import * as s from './Logo.module.scss'
const cn = classNames.bind(s)

type onUpdateProps = {
  is3D: boolean
  isInteractedWith?: boolean
}

type SemiSphereProps = {
  className: string
  onUpdate: ({ is3D, isInteractedWith }: onUpdateProps) => void
}

// eslint-disable-next-line react/display-name
const SemiSphere = forwardRef<HTMLDivElement, SemiSphereProps>(({ className, onUpdate }, ref) => (
  <div
    ref={ref}
    className={cn('semisphere', className)}
    onPointerEnter={() => {
      onUpdate({ is3D: true, isInteractedWith: true })
    }}
    onPointerLeave={() => {
      onUpdate({ is3D: false })
    }}
  />
))

const SemiSpheresMarkup = () => {
  const rRef = useRef<HTMLDivElement>(null)
  const crRef = useRef<HTMLDivElement>(null)
  const clRef = useRef<HTMLDivElement>(null)
  const lRef = useRef<HTMLDivElement>(null)

  const updateR = useCanvas(
    <SemiSphereScene
      el={rRef}
      is3D={false}
      outlineType='right'
      modelColor='white'
      isInteractedWith={false}
      isFlipped
      delayIndex={3}
    />,
  )
  const updateCR = useCanvas(
    <SemiSphereScene
      el={crRef}
      is3D={false}
      outlineType='right'
      modelColor='blue'
      isInteractedWith={false}
      isFlipped
      delayIndex={2}
    />,
  )
  const updateCL = useCanvas(
    <SemiSphereScene
      el={clRef}
      is3D={false}
      outlineType='left'
      modelColor='purple'
      isInteractedWith={false}
      delayIndex={1}
    />,
  )
  const updateL = useCanvas(
    <SemiSphereScene
      el={lRef}
      is3D={false}
      outlineType='left'
      modelColor='white'
      isInteractedWith={false}
      delayIndex={0}
    />,
  )

  return (
    <>
      <SemiSphere ref={rRef} className={'r'} onUpdate={updateR} />
      <SemiSphere ref={crRef} className={'cr'} onUpdate={updateCR} />
      <SemiSphere ref={clRef} className={'cl'} onUpdate={updateCL} />
      <SemiSphere ref={lRef} className={'l'} onUpdate={updateL} />
    </>
  )
}

const Logo = () => {
  const [isInView, setIsInView] = useState(false)
  const isLoaded = useUIContext(s => s.isLoaded)

  return (
    <ViewportEnter
      onEnter={() => {
        setIsInView(true)
      }}
    >
      <div className={cn('logo', { isInView })}>
        <div className={cn('inner')}>
          <SVG isInView={isInView && isLoaded} />
        </div>
        <div className={cn('aux')}>
          <SemiSpheresMarkup />
        </div>
      </div>
    </ViewportEnter>
  )
}

export default memo(Logo)
