//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { Group, Mesh } from 'three'
import { useSpring, a } from '@react-spring/three'
import { easeExpOut } from 'd3-ease'
import { GLTF } from 'three-stdlib'

import purpleGradient from 'assets/images/model-gradients/purple.png'
import model from 'assets/sphere/model.glb'

useTexture.preload(purpleGradient)
useGLTF.preload(model, false)

const sizeFactor = 0.051 //based on model size

const material = {
  metalness: 0.14000000059604645,
  roughness: 0.20000000298023224,
}

type GLTFResult = GLTF & {
  nodes: {
    sphere: Mesh
  }
  materials: unknown
}

type SphereProps = {
  isSceneActive: boolean
  size: number
}

const Sphere = ({ isSceneActive, size, ...props }: SphereProps) => {
  const { nodes } = useGLTF(model, false) as GLTFResult
  const group = useRef<Group>()
  const mesh = useRef<Mesh>()
  const scale = size * sizeFactor
  const texture = useTexture(purpleGradient)

  const materialSpring = useSpring({
    'material-opacity': isSceneActive ? 1 : 0,
    delay: isSceneActive ? 100 : 2300,
    config: {
      duration: 1000,
      easing: easeExpOut,
    },
  })

  const groupSpring = useSpring({
    rotation: isSceneActive ? [0, 0, 0] : [0, Math.PI, 0],
    delay: isSceneActive ? 100 : 1500,
    config: {
      duration: isSceneActive ? 2000 : 1200,
      easing: easeExpOut,
    },
  })

  return (
    <>
      <a.group ref={group} {...props} dispose={null} {...groupSpring} scale={scale}>
        <a.mesh
          ref={mesh}
          castShadow
          receiveShadow
          geometry={nodes['half_sphere-white'].geometry}
          rotation={[0, Math.PI * 1.5, 0]}
          {...materialSpring}
        >
          <meshStandardMaterial
            transparent
            metalness={material.metalness}
            roughness={material.roughness}
            map={texture}
          />
        </a.mesh>
      </a.group>
    </>
  )
}

export default Sphere
