import React, { useState, useLayoutEffect } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'

import Caption from 'components/ui/Caption'
import Content from 'components/ui/Content'
import GradientGrid from 'components/ui/GradientGrid'
import Line from 'components/ui/Line'
import Model from 'components/ui/Model'
import Number1 from 'assets/svg/1.react.svg'
import Number2 from 'assets/svg/2.react.svg'
import Number3 from 'assets/svg/3.react.svg'
import NumberBlock from 'components/ui/NumberBlock'
import { PlutosphereDark, MultiAppToolbox, PlutoApp, OpenEcosystem } from 'components/xr/Objects'

import * as s from './GridLayout.module.scss'
const mediumBreakpoint = parseInt(s.breakpointMedium)

const cn = classNames.bind(s)

const GridLayout = () => {
  const [width] = useWindowSize({ wait: 200 })

  const [isDesktopGrid, setIsDesktopGrid] = useState<boolean | null>(null)
  useLayoutEffect(() => setIsDesktopGrid(width >= mediumBreakpoint), [width])

  return (
    <section className={cn('section')}>
      <div className={cn('row', 'reverse')}>
        <Content
          caption='Plutosphere'
          title='Access XR PCs from anywhere'
          body='Pluto is helping you access the power of XR PCs, regardless of what kind of device you have. Stream and play high-end PC VR games over the internet without needing a computer.'
          button={{
            text: 'Learn more',
            background: 'linear-gradient(36.38deg, #ff9548 14.52%, #db31b6 66.82%, #b91de0 83.53%)',
            url: 'https://www.plutosphere.com/',
          }}
        />

        <div className={cn('group')}>
          {isDesktopGrid !== null && (
            <Model
              Model={PlutosphereDark}
              size={isDesktopGrid ? 0.8 : 1.1}
              activeSize={isDesktopGrid ? 1 : 1.5}
              custom2DPerspective={{ fov: 120 }}
              rotation={[Math.PI * 0.5, 0, 0]}
            />
          )}

          <NumberBlock number={<Number1 />} isArrowLeft />
        </div>

        <Caption className={cn('caption')} hideOnDesktop>
          Plutosphere
        </Caption>

        <Line position='bottom' />
      </div>

      <div className={cn('row')}>
        <Caption className={cn('caption')} hideOnDesktop>
          Developer Portal
        </Caption>

        <div className={cn('group')}>
          <NumberBlock number={<Number2 />} />

          <div className={cn('modelWrapper')}>
            <div className={cn('modelLineWrapper')}>
              <Caption hideOnMobile hasLine={false}>
                &nbsp;
              </Caption>

              <Line position='bottom' className={cn('model2Line')} hideOnMobile duration={1} delay={0.5} />
            </div>

            {isDesktopGrid !== null && (
              <Model
                Model={MultiAppToolbox}
                size={isDesktopGrid ? 1.02 : 1.48}
                activeSize={isDesktopGrid ? 1.15 : 1.6}
                rotation={[0, Math.PI / 2, 0]}
                activeRotation={[-Math.PI * 0.33, 0, Math.PI * 0.4]}
              />
            )}
          </div>
        </div>

        <Content
          caption='Developer Portal'
          title='Visit our Developer Portal'
          body='Develop XR applications that work with the open ecosystem and that can run locally on standards based XR devices or be streamed on PlutoSphere. Discover multi-app and multi-user tools and projects and how to create next-gen XR content for OpenXR and WebXR.'
          button={{
            text: 'Learn more',
            background: 'linear-gradient(36.38deg, #f2ffa1 14.52%, #ff9548 52.2%, #ff4848 85.21%)',
            url: 'https://dev.pluto.app/',
          }}
          isRight
          hasCaptionLine={false}
        />
      </div>

      <GradientGrid />

      <div className={cn('row', 'reverse')}>
        <Content
          caption='Pluto app'
          title='Connect as if you were in person'
          button={{
            text: 'Download app',
            background: 'linear-gradient(36.38deg, #005ecd 14.52%, #55fff5 52.23%, #55ff7a 85.21%)',
            url: 'https://store.steampowered.com/app/389980/Pluto/',
          }}
          body='Experience a more personal, intuitive and trustworthy ways to communicate in a remote environment. The whole
            Pluto experience is designed around the principles of shared presence, which makes communication feel more
            real.'
          isSmall
          hasArrow
        />

        <div className={cn('group')}>
          {isDesktopGrid !== null && (
            <Model
              Model={PlutoApp}
              size={isDesktopGrid ? 1.69 : 2.4}
              activeSize={isDesktopGrid ? 1.4 : 2.1}
              rotation={[Math.PI, Math.PI / 2, 0]}
              activeRotation={[Math.PI, Math.PI * 0.33, Math.PI * -0.22]}
            />
          )}

          <NumberBlock
            number={<Number3 />}
            text='Experience a more personal, intuitive and trustworthy ways to communicate in a remote environment. The whole
              Pluto experience is designed around the principles of shared presence, which makes communication feel more
              real.'
            isArrowLeft
          />
        </div>

        <Caption className={cn('caption')} hideOnDesktop>
          Pluto app
        </Caption>

        <Line position='bottom' />
      </div>

      <div className={cn('row', 'reverse', 'isSmallHeight')}>
        <Content
          caption='Open ecosystem'
          title='Contributing to open standards'
          body='Pluto bases on industry standards and is actively part of an open ecosystem. Sharing the method and the means in which anyone can make apps that work alongside each other, and with people, no matter where they are.'
          isLarge
          hasCaptionLine={false}
        />

        <div className={cn('group')}>
          <div className={cn('modelWrapper')}>
            <div className={cn('modelLineWrapper')}>
              <Caption hideOnMobile hasLine={false}>
                &nbsp;
              </Caption>

              <Line position='bottom' className={cn('model4Line')} hideOnMobile duration={1} delay={0.5} />
            </div>

            {isDesktopGrid !== null && (
              <Model
                Model={OpenEcosystem}
                isSquare
                size={isDesktopGrid ? 1.4 : 1.2}
                activeSize={isDesktopGrid ? 1.2 : 1.25}
                activeRotation={[Math.PI * 0.1, Math.PI * -0.35, 0]}
                position={[0.0005, isDesktopGrid ? -0.001 : 0.01, 0]}
                activePosition={[0, isDesktopGrid ? -0.01 : 0, 0]}
              />
            )}
          </div>
        </div>

        <Caption className={cn('caption')} hideOnDesktop>
          Open ecosystem
        </Caption>
      </div>
    </section>
  )
}

export default GridLayout
