import React, { useEffect, useRef, useState } from 'react'

import useUIContext from 'context/ui'
import useRelativePointerPosition from 'lib/useRelativePointerPosition'
import XRButton from 'components/xr/XRButton'
import checkHoverSupport from 'lib/checkHoverSupport'

import * as s from './PlutoLogoStickyHoverTarget.module.scss'

export default function PlutoLogoStickyHoverTarget(props: {
  onLogoHover: (hover: boolean) => void
  onMouseMoveOverLogo: (pos: { x: number; y: number }) => void
}) {
  const [hasHoverSupport, setHasHoverSupport] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  const isLoaderAnimated = useUIContext(s => s.isLoaderAnimated)
  const localLogoRef = useRef<HTMLDivElement>(null)
  const mouse = useRef({ x: 0, y: 0 }).current

  useRelativePointerPosition(localLogoRef.current, relativeMouse => props.onMouseMoveOverLogo(relativeMouse))

  const onMouseMove = (event: React.MouseEvent) => {
    isLoaderAnimated && setIsMouseOver(true)

    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  useEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  return (
    <>
      <div
        ref={localLogoRef}
        className={s.hoverTarget}
        onPointerEnter={() => props.onLogoHover(true)}
        onPointerLeave={() => {
          props.onLogoHover(false)
          setIsMouseOver(false)
        }}
        onPointerMove={onMouseMove}
      >
        {hasHoverSupport && <XRButton mouse={mouse} isMouseOver={isMouseOver} />}
      </div>
    </>
  )
}
