// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var hero = "Hero-module--hero--a0LDP";
export var stars1 = "Hero-module--stars1--4t309";
export var stars2 = "Hero-module--stars2--GVpYa";
export var grid = "Hero-module--grid--5mgb4";
export var sideWrapper = "Hero-module--sideWrapper--skc+W";
export var leftWrapper = "Hero-module--leftWrapper--Art48";
export var mainWrapper = "Hero-module--mainWrapper--l+rp3";
export var rightWrapper = "Hero-module--rightWrapper--yMO4Q";
export var diamondMobileWrapper = "Hero-module--diamondMobileWrapper--pPRH-";
export var isInView = "Hero-module--isInView--Zy5H5";
export var diamondMobile = "Hero-module--diamondMobile--DeGPZ";