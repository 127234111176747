import React, { useState } from 'react'
import classNames from 'classnames/bind'

import { CaptionText } from 'components/ui/Text'
import ViewportEnter from 'components/core/ViewportEnter'

import { CaptionTitleType } from './CaptionTitleTypes'
import * as s from './CaptionTitle.module.scss'

const cn = classNames.bind(s)

const CaptionTitle = ({ children, duration = 0.5, delay = 0 }: CaptionTitleType) => {
  const [isInView, setIsInView] = useState(false)
  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div
        className={cn('wrapper', { isInView })}
        style={{ transitionDuration: `${duration}s`, transitionDelay: `${delay}s` } as React.CSSProperties}
      >
        <CaptionText>{children}</CaptionText>
      </div>
    </ViewportEnter>
  )
}
export default CaptionTitle
