// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "GradientGrid-module--container--oCONl";
export var wrapper = "GradientGrid-module--wrapper--KvC3V";
export var gradient = "GradientGrid-module--gradient--N5Acy";
export var topLine = "GradientGrid-module--topLine--txxvr";
export var stretch = "GradientGrid-module--stretch--5ntA4";
export var verticalLine1 = "GradientGrid-module--verticalLine1--y8Ebf";
export var verticalLine2 = "GradientGrid-module--verticalLine2--MwZd-";
export var verticalLine3 = "GradientGrid-module--verticalLine3--gUncv";
export var verticalLine4 = "GradientGrid-module--verticalLine4--dYwG7";
export var verticalLine5 = "GradientGrid-module--verticalLine5--pcOM9";