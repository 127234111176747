// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var about = "About-module--about--5a+OK";
export var isMainInView = "About-module--isMainInView--8mL6N";
export var aux2 = "About-module--aux2--NEbkV";
export var aux1 = "About-module--aux1--SOrHP";
export var header = "About-module--header--Bk68k";
export var content = "About-module--content--Mnjr6";
export var isAux1InView = "About-module--isAux1InView--jHgGT";
export var line = "About-module--line--xCOQj";
export var diamond = "About-module--diamond--UIbfx";
export var sphere = "About-module--sphere--Zb07P";
export var semispheresMobile = "About-module--semispheresMobile--GIDHR";
export var scaleUpX = "About-module--scaleUpX--Ws1N0";