import React from 'react'
import { useLoader } from '@react-three/fiber'
import { TextureLoader, LinearFilter } from 'three'
import { a, useSpring } from '@react-spring/three'
import { easeExpIn, easeQuadOut } from 'd3-ease'
import { useTexture } from '@react-three/drei'

import outline from 'assets/diamond/outline.png'
useTexture.preload(outline)

type OutlineProps = {
  isSceneActive: boolean
  size: number
  isInView: boolean
  isInteractedWith?: boolean
}

const Outline = ({ isSceneActive, size, isInView, isInteractedWith = false }: OutlineProps) => {
  const texture = useLoader(TextureLoader, outline)
  texture.minFilter = LinearFilter

  const { opacity } = useSpring({
    opacity: isInView ? (isSceneActive ? 0 : 1) : 0,
    delay: isSceneActive ? 0 : isInteractedWith ? 2000 : 500,
    config: {
      duration: isSceneActive ? 150 : 1000,
      easing: isSceneActive ? easeExpIn : easeQuadOut,
    },
  })

  return (
    <a.mesh renderOrder={1} material-opacity={opacity}>
      <planeBufferGeometry attach='geometry' args={[size, size]} />
      <meshBasicMaterial attach='material' map={texture} transparent={true} depthTest={false} />
    </a.mesh>
  )
}

export default Outline
