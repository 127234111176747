import React, { useState, useRef, Ref, forwardRef } from 'react'
import classNames from 'classnames/bind'
import { useCanvas } from '@14islands/r3f-scroll-rig'

import DiamondScene from 'components/xr/DiamondScene'
import Stars from 'components/ui/Stars'
import ViewportEnter from 'components/core/ViewportEnter'

import XR from './XR'
import About from './About'
import Main from './Main'
import Side from './Side'
import Logo from './Logo'
import CTA from './CTA'
import Outro from './Outro'
import * as s from './Hero.module.scss'

const cn = classNames.bind(s)

const DiamondMobile = () => {
  const [isInView, setIsInView] = useState(false)
  const diamondRef = useRef<HTMLDivElement>(null)
  useCanvas(<DiamondScene el={diamondRef} is3D={false} isInView isIdle />)

  return (
    <ViewportEnter
      onEnter={() => {
        setIsInView(true)
      }}
      once={false}
    >
      <div className={cn('diamondMobileWrapper', { isInView })}>
        <div className={cn('diamondMobile')} ref={diamondRef} />
      </div>
    </ViewportEnter>
  )
}

export default forwardRef(function Hero(
  props: {
    onLogoHover: (hover: boolean) => void
    onMouseMoveOverLogo: (pos: { x: number; y: number }) => void
  },
  logoAreaRef: Ref<HTMLDivElement>,
) {
  return (
    <div className={cn('hero')}>
      <Stars className={cn('stars1')} />
      <Stars className={cn('stars2')} animationDuration={2} animationDelay={0.2} />
      <div className={cn('grid')}>
        <div className={cn('sideWrapper')}>
          <Side />
        </div>
        <div className={cn('leftWrapper')}>
          <XR />
          <About />
          <div className={cn('leftAux')} />
          <div className={cn('leftBtm')} />
        </div>
        <div className={cn('mainWrapper')}>
          <Main ref={logoAreaRef} onLogoHover={props.onLogoHover} onMouseMoveOverLogo={props.onMouseMoveOverLogo} />
        </div>
        <div className={cn('rightWrapper')}>
          <DiamondMobile />
          <Logo />
          <CTA />
        </div>
      </div>
      <Outro />
    </div>
  )
})
