import React, { useState } from 'react'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'

import * as s from './DiamondGrid.module.scss'

const cn = classNames.bind(s)

const DiamondGrid = () => {
  const [isInView, setIsInView] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('diamondGrid', { isInView })} />
    </ViewportEnter>
  )
}

export default DiamondGrid
