import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import classNames from 'classnames/bind'

import Noise from 'components/ui/Noise'
import Line from 'components/ui/Line'
import { FileWithFluidGatsbyImage } from 'types/GatsbyImage'

import * as s from './GradientGrid.module.scss'

const cn = classNames.bind(s)

type QueryData = {
  gradient: FileWithFluidGatsbyImage
}

const query = graphql`
  query GradientGridQuery {
    gradient: file(relativePath: { eq: "images/mid-gradient-green.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const GradientGrid = () => {
  const { gradient } = useStaticQuery(query) as QueryData

  return (
    <div className={cn('container')}>
      <Line className={cn('topLine')} position='top' duration={1.2} delay={0.1} />

      <div className={cn('wrapper')}>
        <div className={cn('gradient')}>
          <GatsbyImage className={cn('stretch')} fluid={gradient.childImageSharp.fluid} />
        </div>

        <Noise absolute />
      </div>

      <Line position='left' className={cn('verticalLine1')} hideOnMobile duration={1.2} delay={0.2} />
      <Line position='left' className={cn('verticalLine2')} hideOnMobile duration={1.2} delay={0.4} />
      <Line position='left' className={cn('verticalLine3')} hideOnMobile duration={1.2} delay={0.6} />
      <Line position='right' className={cn('verticalLine4')} hideOnMobile duration={1.2} delay={0.8} />
      <Line position='right' className={cn('verticalLine5')} duration={1.2} delay={1} />

      <Line position='bottom' duration={1.2} delay={0.4} />
    </div>
  )
}

export default GradientGrid
