import React, { useEffect, useRef, useState, forwardRef, Ref, MouseEvent } from 'react'
import classNames from 'classnames/bind'
import mergeRefs from 'react-merge-refs'

import useRelativePointerPosition from 'lib/useRelativePointerPosition'
import checkHoverSupport from 'lib/checkHoverSupport'
import Arrow from 'components/ui/Arrow'
import XRButton from 'components/xr/XRButton'

import * as s from './Arrows.module.scss'

const cn = classNames.bind(s)

const Arrows = (
  props: {
    onLogoHover: (hover: boolean) => void
    onMouseMoveOverLogo: (pos: { x: number; y: number }) => void
  },
  logoAreaRef: Ref<HTMLDivElement>,
) => {
  const [hasHoverSupport, setHasHoverSupport] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  const localLogoRef = useRef<HTMLDivElement>(null)
  const logoAreaLocalRef = useRef<HTMLDivElement>(null)
  const mouse = useRef({ x: 0, y: 0 }).current

  useRelativePointerPosition(localLogoRef.current, relativeMouse => props.onMouseMoveOverLogo(relativeMouse))

  const onMouseMove = (event: MouseEvent) => {
    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  useEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  return (
    <div className={cn('container')}>
      <div className={cn('rows')}>
        <div className={cn('row')}>
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} />
        </div>

        <div className={cn('row')}>
          <Arrow rotation={135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} showOnMobile />
          <Arrow rotation={135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} />
        </div>

        <div className={cn('row')}>
          <Arrow rotation={90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} showOnMobile />
          <Arrow rotation={90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} />
        </div>

        <div className={cn('row')}>
          <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} showOnMobile />
          <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} />
        </div>
      </div>

      <div
        ref={mergeRefs([logoAreaLocalRef, logoAreaRef])}
        className={cn('pArea')}
        onPointerEnter={() => {
          if (hasHoverSupport) {
            setIsMouseOver(true)
            props.onLogoHover(true)
          }
        }}
        onPointerLeave={() => {
          if (hasHoverSupport) {
            setIsMouseOver(false)
            props.onLogoHover(false)
          }
        }}
        onPointerMove={onMouseMove}
      >
        <XRButton mouse={mouse} isMouseOver={isMouseOver}>
          <div ref={localLogoRef} className={cn('plutoLogoPlaceholder')} />
        </XRButton>
      </div>

      <div className={cn('rows')}>
        <div className={cn('row')}>
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={180} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} />
        </div>

        <div className={cn('row')}>
          <Arrow rotation={-135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} showOnMobile />
          <Arrow rotation={-135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={-135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={-135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={-135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={-135} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} />
        </div>

        <div className={cn('row')}>
          <Arrow rotation={-90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} showOnMobile />
          <Arrow rotation={-90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={-90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={-90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={-90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={-90} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} />
        </div>

        <div className={cn('row')}>
          <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.5} showOnMobile />
          <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.4} />
          <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.3} />
          <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.2} />
          <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0.1} />
          <Arrow rotation={-45} mouse={mouse} isMouseOver={isMouseOver} animationDelay={0} />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(Arrows)
