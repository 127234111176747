// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Model-module--container--AcWxz";
export var modelPlaceholder = "Model-module--modelPlaceholder--IEUXE";
export var modelStrokeArt = "Model-module--modelStrokeArt--gGbjL";
export var isSquare = "Model-module--isSquare--6ccjZ";